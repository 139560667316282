import { Item } from "../../ui/menu/Item";
import { IconMenu } from "../../ui/menu/IconMenu";
import { ItemDetailHeader } from "../../layout/ItemDetailHeader";
import { AnimatedButton } from "../../ui/buttons/AnimatedButton";
import { Check } from "@mui/icons-material";
import { CareTemplate } from "../../../model/templates/CareTemplate";
import { WardRoundEntry } from "../../../model/ward-round/WardRoundEntry";
import { DeleteCareDialog } from "./tabs/ward-rounds/dialogs/DeleteCareDialog";
import { useState } from "react";
import { CareDetailDialog } from "./tabs/ward-rounds/dialogs/CareDetailDialog";
import { CareTemplateChallenge } from "../../../model/templates/CareTemplateChallenge";
import { useForm } from "react-hook-form";
import { CareTemplateTabs } from "./tabs/CareTemplateTabs";
import { useAsyncCall } from "react-hook-async-call";
import { exportCareTemplate } from "../../../services/care-template/exportCareTemplate";
import { downloadFile } from "../../../helpers/downloadFile";
import { useAlerts } from "../../../hooks/useAlerts";
import { Chip } from "@mui/material";

interface Props {
  selectedCare: CareTemplate;
  onSaveWardRound: (data: WardRoundEntry, index?: number) => void;
  onDeleteWardRound: (index: number) => void;
  onSave: (challenges: CareTemplateChallenge[]) => void;
  onDelete: () => void;
  onEditCare: (care: CareTemplate) => void;
}

export const CareTemplateDetail = (props: Props) => {
  const alerts = useAlerts();
  const form = useForm<CareTemplateChallenge[]>();

  const [deleteCareDialogOpen, setDeleteCareDialogOpen] = useState<boolean>(false);
  const [openDetailDialog, setOpenDetailDialog] = useState<boolean>(false);

  const [exportCareCall] = useAsyncCall(
    exportCareTemplate,
    undefined,
    (content) => downloadFile(content, props.selectedCare.title),
    (e) => alerts.error(e)
  );

  const chips = props.selectedCare.type && <Chip label={props.selectedCare.type} />;

  return (
    <>
      <ItemDetailHeader title={props.selectedCare.title} chips={chips}>
        <AnimatedButton
          onClick={() => {
            props.onSave(Object.values(form.getValues()));
          }}
          animation={"scale"}
          endIcon={<Check />}
          variant={"contained"}
          color={"success"}
        >
          Uložit
        </AnimatedButton>
        <IconMenu>
          <Item title={"Editovat šablonu"} onClick={() => setOpenDetailDialog(true)} />
          <Item title={"Smazat šablonu"} onClick={() => setDeleteCareDialogOpen(true)} />
          <Item title={"Exportovat šablonu"} onClick={() => exportCareCall(props.selectedCare.id)} />
        </IconMenu>
      </ItemDetailHeader>
      <CareTemplateTabs
        care={props.selectedCare}
        form={form}
        onSaveWardRound={props.onSaveWardRound}
        onDeleteWardRound={props.onDeleteWardRound}
      />
      <DeleteCareDialog
        onClose={() => setDeleteCareDialogOpen(false)}
        open={deleteCareDialogOpen}
        onConfirm={props.onDelete}
      />
      <CareDetailDialog
        open={openDetailDialog}
        onClose={() => setOpenDetailDialog(false)}
        onSubmit={props.onEditCare}
        data={props.selectedCare}
      />
    </>
  );
};
