import { QuestionTemplate } from "../../model/templates/QuestionTemplate";
import { api } from "../api";
import { fromQuestionTemplateToGenerated, parseQuestionTemplate } from "../../parsers/template/parseQuestionTemplate";

export const putQuestionTemplate = async (questionTemplateId: string, questionTemplate: QuestionTemplate) => {
  const result = await api.services.questionTemplate.updateQuestionTemplate(
    questionTemplateId,
    fromQuestionTemplateToGenerated(questionTemplate)
  );
  return parseQuestionTemplate(result.data);
};
