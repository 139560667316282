import { Stack, Typography } from "@mui/material";
import { ReactNode } from "react";

interface Props {
  title?: string;
  children: ReactNode;
}

export const FormSectionWrapper = ({ title, children }: Props) => {
  return (
    <Stack>
      {title && (
        <Typography variant={"h3"} sx={{ fontSize: 18 }}>
          {title}
        </Typography>
      )}
      <Stack gap={1}>{children}</Stack>
    </Stack>
  );
};
