import { WardRoundEntry } from "../../../model/ward-round/WardRoundEntry";
import { WardRoundEntryDtoIn } from "../../../generated";
import { fromRepetitionGranularityToGenerated } from "../../parseRepetitionGranularity";

export const fromWardRoundEntryToGenerated = (data: WardRoundEntry): WardRoundEntryDtoIn => ({
  wardRoundTemplateId: data.wardRoundTemplate.id,
  offsetDays: data.offsetDays,
  isRecurring: data.isRecurring,
  repetitionGranularity: data.repetitionGranularity && fromRepetitionGranularityToGenerated(data.repetitionGranularity),
  repetitionValue: data.repetitionValue,
  numberOfRepetitions: data.numberOfOccurrences,
  start: data.start,
});
