import { ReactNode } from "react";
import { Stack, Typography } from "@mui/material";
import { Card } from "../../../../ui/cards/Card";

interface Props {
  children: ReactNode;
}

export const ScheduleStartEndItem = (props: Props) => {
  return (
    <Card sx={{ height: 85, width: 135 }} variant={"contained"}>
      <Stack sx={{ justifyContent: "center", alignItems: "center", height: "100%" }}>
        <Typography sx={{ textAlign: "center", marginBottom: 0 }} variant={"h4"}>
          {props.children}
        </Typography>
      </Stack>
    </Card>
  );
};
