import { QuestionType as QuestionTypeGenerated } from "../../generated";
import { QuestionType } from "../../model/enum/QuestionType";

export const parseQuestionType = (data: QuestionTypeGenerated): QuestionType => {
  switch (data) {
    case QuestionTypeGenerated.FreeText:
      return QuestionType.FreeTextAnswer;
    case QuestionTypeGenerated.MultiChoice:
      return QuestionType.MultiChoiceAnswer;
    case QuestionTypeGenerated.Number:
      return QuestionType.NumberAnswer;
    case QuestionTypeGenerated.SingleChoice:
      return QuestionType.SingleChoiceAnswer;
    case QuestionTypeGenerated.WellBeing:
      return QuestionType.WellBeing;
    case QuestionTypeGenerated.Rating:
      return QuestionType.Rating;
  }
};
