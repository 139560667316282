import { AbstractDialog } from "../../../ui/dialogs/AbstractDialog";
import { Button, Divider, Grid, Stack } from "@mui/material";
import { QuestionIcon } from "../icons/QuestionIcon";
import { QuestionType } from "../../../../model/enum/QuestionType";
import { Colors } from "../../../../theme/colors";
import { TextInput } from "../../../ui/form/inputs/TextInput";
import { useFieldArray, useForm } from "react-hook-form";
import { QuestionTemplate, QuestionTemplateBase } from "../../../../model/templates/QuestionTemplate";
import { InputWrapper } from "../../../ui/form/inputs/InputWrapper";
import { AnimatedButton } from "../../../ui/buttons/AnimatedButton";
import AddIcon from "@mui/icons-material/Add";
import { SeverityStatus } from "../../../../model/enum/SeverityStatus";
import CheckIcon from "@mui/icons-material/Check";
import { AnswerTemplate } from "../parts/AnswerTemplate";
import { postQuestionTemplate } from "../../../../services/question-template/postQuestionTemplate";
import { useAsyncCall } from "react-hook-async-call";
import { useAlerts } from "../../../../hooks/useAlerts";
import { putQuestionnaireTemplate } from "../../../../services/questionnaire-template/putQuestionnaireTemplate";
import { QuestionnaireTemplate } from "../../../../model/templates/QuestionnaireTemplate";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { IconColors } from "../../../../theme/utils";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { useState } from "react";
import { isFormDirty, isFormValid } from "../../../../helpers/form/isFormValid";
import { putQuestionTemplate } from "../../../../services/question-template/putQuestionTemplate";
import { Save } from "@mui/icons-material";
import { EmojiInput } from "../../../ui/form/inputs/EmojiInput";
import { EmojiMood } from "../../../../model/enum/EmojiMood";
import { EmojiType } from "../../../../model/enum/EmojiType";
import { SelectInput } from "../../../ui/form/inputs/SelectInput";
import { SelectInputOption } from "../../../ui/form/inputs/props/SelectInputProps";
import { AnswerTemplate as AnswerTemplateType } from "../../../../model/templates/AnswerTemplate";
import { useOnMount } from "../../../../hooks/useOnMount";

interface Props {
  questionnaire: QuestionnaireTemplate;
  questionType: QuestionType;
  onSubmit: (questionnaireTemplate: QuestionnaireTemplate) => void;
  onClose: () => void;
  dialogOpen: boolean;
  data?: QuestionTemplate;
}

export const QuestionTemplateDetailDialog = (props: Props) => {
  const isEditing = !!props.data;
  const alerts = useAlerts();
  const conditionQuestions: SelectInputOption<QuestionTemplate>[] = props.questionnaire.questions
    .filter((q) => q.type === QuestionType.SingleChoiceAnswer && q.id !== props.data?.id)
    .map((q) => ({
      key: q.id,
      label: q.text,
      value: q,
    }));

  // used to validate if user did something with the form
  const [orderChanged, setOrderChanged] = useState<boolean>(false);
  const [saveQuestionTemplate] = useAsyncCall(postQuestionTemplate, undefined, (result) => {
    submitQuestionnaireTemplate(props.questionnaire.id, {
      ...props.questionnaire,
      questions: [...props.questionnaire.questions, result],
    });
  });

  const [updateQuestionTemplate] = useAsyncCall(putQuestionTemplate, undefined, (result) => {
    submitQuestionnaireTemplate(props.questionnaire.id, {
      ...props.questionnaire,
      questions: props.questionnaire.questions.map((question) => (question.id === result.id ? result : question)),
    });
  });

  const [submitQuestionnaireTemplate] = useAsyncCall(putQuestionnaireTemplate, undefined, (result) => {
    form.reset();
    alerts.success(isEditing ? "Otázka úspěšne uložena" : "Otážka úspěšne vytvořena");
    props.onSubmit(result);
  });
  const defaultValues = {
    type: props.questionType,
    text: "",
    // TODO: Allow selecting custom SeverityStatus in UI, ISSUE #946
    answers:
      props.questionType === QuestionType.WellBeing
        ? Object.values(EmojiMood).map((mood) => {
            return { id: "", text: mood, severity: SeverityStatus.No, alertNote: "" };
          })
        : [{ id: "", text: "", severity: SeverityStatus.No, alertNote: "" }],
  };

  const form = useForm<QuestionTemplate>({ mode: "onChange", defaultValues });
  const fieldArray = useFieldArray({
    name: "answers",
    control: form.control,
  });
  const conditionsArray = useFieldArray({
    name: "conditions",
    control: form.control,
  });

  useOnMount(() => {
    if (props.data) {
      form.reset(props.data);

      if (!props.data.conditions.length) {
        conditionsArray.append({});
      }
    }
  });

  const isValid = (): boolean => {
    if (!isFormDirty(form) && !orderChanged) return false;
    return isFormValid(form);
  };

  const buttons = (
    <Button
      color={"success"}
      endIcon={isEditing ? <Save /> : <CheckIcon />}
      onClick={() => handleSubmit()}
      disabled={!isValid()}
    >
      {isEditing ? "Uložit otázku" : "Přidat otázku"}
    </Button>
  );

  const addNewAnswer = () => {
    fieldArray.append({ id: "", text: "", severity: SeverityStatus.No, alertNote: "" });
  };

  const handleSubmit = () => {
    isEditing && props.data?.id
      ? updateQuestionTemplate(props.data.id, form.getValues())
      : saveQuestionTemplate(form.getValues());
    props.onClose();
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    fieldArray.move(result.source.index, result.destination.index);
    setOrderChanged(true);
  };

  const handleClose = () => {
    props.onClose();

    // because of fadeout animation
    setTimeout(() => {
      form.reset();
      fieldArray.remove();
      addNewAnswer();
    }, 200);
  };

  const titleIcon = (
    <Stack sx={{ color: Colors.grey500 }}>
      <QuestionIcon questionType={props.questionType} />
    </Stack>
  );

  return (
    <AbstractDialog
      open={props.dialogOpen}
      onClose={handleClose}
      title={props.questionType}
      buttons={buttons}
      titleIcon={titleIcon}
      width={"md"}
      alertOnClose={form.formState.isDirty}
    >
      <TextInput
        name={"text"}
        form={form}
        multiline
        title={"Text otázky"}
        placeholder={"Např. Byl jste v posledním týdnu hospitalizován?"}
        required
        fieldError={form.formState.errors.text}
      />
      {(props.questionType === QuestionType.SingleChoiceAnswer ||
        props.questionType === QuestionType.MultiChoiceAnswer) && (
        <InputWrapper
          title={"Možnosti odpovědí"}
          required
          buttons={
            <AnimatedButton
              onClick={addNewAnswer}
              animation={"scale"}
              endIcon={<AddIcon />}
              color={"success"}
              variant={"text"}
            >
              přidat
            </AnimatedButton>
          }
        >
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId={"answers"}>
              {(provided) => (
                <Stack gap={0.5} {...provided.droppableProps} ref={provided.innerRef}>
                  {fieldArray.fields.map((answer, index) => (
                    <Draggable key={answer.id} draggableId={answer.id} index={index}>
                      {(provided) => (
                        <Stack
                          alignItems={"center"}
                          direction={"row"}
                          gap={2}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <Stack {...provided.dragHandleProps} sx={IconColors}>
                            <DragHandleIcon />
                          </Stack>
                          <AnswerTemplate form={form} index={index} fieldArray={fieldArray} />
                        </Stack>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Stack>
              )}
            </Droppable>
          </DragDropContext>
        </InputWrapper>
      )}
      {props.questionType === QuestionType.WellBeing && (
        <>
          <InputWrapper required title={"Text emojis"}>
            <Stack direction={"row"} justifyContent={"space-evenly"}>
              {Object.values(EmojiMood).map((mood, index) => (
                <EmojiInput mood={mood} type={EmojiType.Transparent} form={form} key={index} index={index} />
              ))}
            </Stack>
          </InputWrapper>
        </>
      )}
      {conditionQuestions.length > 0 && (
        <Stack mt={1} gap={1}>
          <Divider />
          <Stack gap={0.5}>
            {conditionsArray.fields.map((condition, index) => {
              const selectedQuestion: QuestionTemplateBase | undefined = form.watch(`conditions.${index}.question`);
              const answers: SelectInputOption<AnswerTemplateType>[] =
                selectedQuestion?.answers.map((a) => ({
                  key: a.id,
                  label: a.text,
                  value: a,
                })) ?? [];
              return (
                <Grid container key={condition.id} spacing={2}>
                  <Grid item xs={6}>
                    <SelectInput
                      name={`conditions.${index}.question`}
                      required={false}
                      form={form}
                      title={index === 0 ? "Zobrazit pokud v otázce" : undefined}
                      options={conditionQuestions}
                      optionsValueComparator={(a, b) => a && b && a.id === b.id}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SelectInput
                      name={`conditions.${index}.answer`}
                      required={false}
                      form={form}
                      title={index === 0 ? "Byla zvolená odpověď" : undefined}
                      options={answers}
                      optionsValueComparator={(a, b) => a && b && a.id === b.id}
                    />
                  </Grid>
                </Grid>
              );
            })}
          </Stack>
        </Stack>
      )}
    </AbstractDialog>
  );
};
