import { ChallengeSettingsCard } from "../../../../patient-detail/tabs/vitality/ChallengeSettingsCard";
import { InputAdornment, Stack } from "@mui/material";
import { NumberInput } from "../../../../../ui/form/inputs/NumberInput";
import { UseFormReturn } from "react-hook-form";
import { useEffect } from "react";
import { CareTemplateNumericChallenge } from "../../../../../../model/templates/CareTemplateChallenge";

interface Props {
  form: UseFormReturn<CareTemplateNumericChallenge[]>;
  data: CareTemplateNumericChallenge;
  index: number;
}

export const StepsChallenge = (props: Props) => {
  const title = "Kroky";

  useEffect(() => {
    props.form.setValue(`${props.index}`, props.data);
  }, [props.data, props.form, props.index]);

  props.form.register(`${props.index}.minGoal`, {
    validate: (value) => {
      if (!value || value < 1) {
        return "Počet kroků musí být větší než 0";
      }

      if (value % 1 !== 0) {
        return "Počet kroků musí být celé číslo";
      }

      return true;
    },
  });

  return (
    <ChallengeSettingsCard
      title={title}
      enabled={props.data.enabled}
      onEnabledChange={(change) => props.form.setValue(`${props.index}.enabled`, change)}
    >
      <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
        <strong>Denní cíl</strong>
        <NumberInput
          name={`${props.index}.minGoal`}
          title={title}
          hideTitle
          form={props.form}
          step={1000}
          min={1}
          onlyInt
          required
          fieldError={props.form.formState.errors[props.index]?.minGoal}
          inputProps={{ endAdornment: <InputAdornment position="end">kroků</InputAdornment> }}
        />
      </Stack>
    </ChallengeSettingsCard>
  );
};
