import { Colors } from "../colors";
import { SxProps } from "@mui/material";
import { Border, FontFamilyHeadings, FontWeight } from "../utils";

export const outerBoxStyles: SxProps = {
  flexGrow: 1,
};

export const dataGridStyles: SxProps = {
  border: "none",
  fontSize: 13,
  background: "none",
  "& .MuiButton-root": {
    "&.MuiButton-sizeSmall": {
      boxShadow: "none",
      background: "none",
      color: Colors.primary,
      padding: "0 8px",
      "&:hover": {
        background: "none",
        color: Colors.primary,
      },
    },
  },
  ".MuiDataGrid-columnHeaders": {
    minHeight: `50px !important`,
    height: `50px !important`,
    bgcolor: Colors.grey200,
    color: Colors.grey500,
    borderRadius: 1,
    borderBottom: "none",
    fontFamily: FontFamilyHeadings,
    ".MuiDataGrid-columnHeadersInner": {
      pb: "10px",
    },
  },
  ".MuiDataGrid-virtualScroller": {
    marginTop: `35px !important`,
    overflow: "visible !important",
  },
  ".MuiDataGrid-main": {
    overflow: "visible !important",
  },
  ".MuiDataGrid-columnHeader": {
    ".MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "&Title": {
      fontWeight: FontWeight.Bold,
    },
  },
  ".MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within": {
    outline: "none",
  },
  ".MuiDataGrid-row": {
    borderRadius: 1,
    border: Border.Grey.Thin,
    transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1)",
    backgroundColor: Colors.white,
    "&:hover": {
      backgroundColor: Colors.grey50,
    },
  },
  ".MuiDataGrid-cell": {
    border: 0,
  },
};

export const dataGridAnimateOnHover: SxProps = {
  ".MuiDataGrid-row": {
    borderRadius: 1,
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 4px 0px;",
    transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1)",
    backgroundColor: Colors.white,
    position: "relative",
    borderLeft: `0 solid ${Colors.primary}`,
    "&:hover": {
      cursor: "pointer",
      bgcolor: Colors.grey50,
      borderLeft: `5px solid ${Colors.primary}`,
    },
    "&.Mui-selected": {
      bgcolor: "white",
      borderLeft: `5px solid ${Colors.primary}`,
      "&:hover": {
        bgcolor: Colors.grey50,
      },
    },
  },
};

export const dataGridHideHeader: SxProps = {
  "& .MuiDataGrid-columnHeaders": { display: "none" },
  "& .MuiDataGrid-virtualScroller": { marginTop: "0!important" },
};
