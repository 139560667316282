import { User } from "../../model/User";
import { getLocalUser } from "../../services/auth/getLocalUser";

export interface AuthState {
  user?: User;
}

export const initialState: AuthState = {
  user: getLocalUser(),
};
