import { DialogProps } from "../../../ui/dialogs/props/DialogProps";
import { AbstractDialog } from "../../../ui/dialogs/AbstractDialog";
import { Alert, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { useAlerts } from "../../../../hooks/useAlerts";
import { Colors } from "../../../../theme/colors";
import { WarningOutlined } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import { TextBox } from "./LoginInfoDialog";

type Props = DialogProps & {
  password: string;
};

export const LoginInfoConfirmationDialog = ({ password, ...props }: Props) => {
  const alerts = useAlerts();

  return (
    <AbstractDialog
      open={props.open}
      onClose={props.onClose}
      width={"md"}
      title={"Nové heslo pacienta"}
      buttons={
        <Stack gap={0.5} alignItems="flex-end">
          <Typography sx={{ color: Colors.error, fontSize: 12 }}>
            Po uzavření tohoto okna nebude možné heslo znovu zobrazit
          </Typography>
          <Button
            onClick={() => {
              alerts.success("Heslo bylo úspěšně nastaveno.");
              props.onClose();
            }}
            endIcon={<CheckIcon />}
          >
            Potvrdit seznámení pacienta s heslem
          </Button>
        </Stack>
      }
    >
      <Stack gap={2} alignItems={"center"}>
        <Alert color={"warning"} icon={<WarningOutlined />}>
          Nezapomeňte sdělit heslo pacientovi.
        </Alert>
        <TextBox value={password} />
      </Stack>
    </AbstractDialog>
  );
};
