import { ElementType, MouseEvent, ReactNode, useState } from "react";
import { AbstractMenu } from "./AbstractMenu";
import { Button, ButtonProps } from "@mui/material";

interface ButtonMenuProps {
  text: string;
  endIcon?: ReactNode;
  children: ReactNode;
  onClick?: never;
}

export const ButtonMenu = <C extends ElementType>({
  children,
  text,
  ...props
}: ButtonProps<C, { component?: C }> & ButtonMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <AbstractMenu
      anchorEl={anchorEl}
      handleClose={() => setAnchorEl(null)}
      clickableEl={
        <Button onClick={handleClick} {...props}>
          {text}
        </Button>
      }
    >
      {children}
    </AbstractMenu>
  );
};
