import { Stack } from "@mui/material";
import { Colors } from "../../../../../theme/colors";
import { BloodPressureValue, isBloodPressureValue } from "../../../../../model/challenge/BloodPressureValue";
import { ChallengeType } from "../../../../../model/enum/Challenge";
import { addSpacesToNumber } from "../../../../../helpers/addSpacesToNumber";

interface Props {
  type?: string;
  value?: number | BloodPressureValue;
  minGoal?: number | BloodPressureValue;
  maxGoal?: number | BloodPressureValue;
}

export const StatsCardGoal = (props: Props) => {
  const goalName = "Cíl: ";

  return (
    <Stack>
      {props.minGoal && (
        <Stack sx={{ color: Colors.grey500, fontSize: 12 }}>
          {(() => {
            if (isBloodPressureValue(props.minGoal)) {
              if (isBloodPressureValue(props.maxGoal)) {
                return (
                  goalName +
                  `${props.minGoal.systolic}/${props.minGoal.diastolic} - ${props.maxGoal.systolic}/${props.maxGoal.diastolic}`
                );
              }
            } else {
              const formattedGoal = addSpacesToNumber(props.minGoal);
              switch (props.type) {
                case ChallengeType.Water:
                  return goalName + `${formattedGoal} sklenic`;
                case ChallengeType.Steps:
                  return goalName + `${formattedGoal}`;
                case ChallengeType.Sleep:
                  return goalName + `${props.minGoal}h`;
                case ChallengeType.HeartRate:
                  return goalName + `${props.minGoal} - ${props.maxGoal}`;
              }
            }
          })()}
        </Stack>
      )}
    </Stack>
  );
};
