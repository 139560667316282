import { Stack, Typography } from "@mui/material";
import { ReactNode } from "react";

interface Props {
  title?: string;
  children: ReactNode;
}

export const FormWrapper = ({ title, children }: Props) => {
  return (
    <Stack>
      {title && <Typography variant={"h2"}>{title}</Typography>}
      <Stack gap={3}>{children}</Stack>
    </Stack>
  );
};
