import { User } from "../model/User";
import { UserWithJwtDtoOut } from "../generated";
import jwtDecode from "jwt-decode";
import { DecodedToken } from "../model/DecodedToken";

export const parseUser = (data: UserWithJwtDtoOut): User => {
  const decoded: DecodedToken = jwtDecode(data.jwt);

  return {
    id: data.user.id,
    token: data.jwt,
    tokenExpiresAt: decoded.exp,
  };
};
