import { UpsertPatientDtoIn } from "../../../generated";
import { PatientCreate } from "../../../model/patient/PatientCreate";
import { fromSexToGenerated } from "./fromSexToGenerated";

export const fromPatientCreateToGenerated = (data: PatientCreate): UpsertPatientDtoIn => {
  return {
    firstName: data.firstName,
    lastName: data.lastName,
    sex: fromSexToGenerated(data.sex),
    tags: data.tags.map((tag) => tag.id),
    personalNumber: data.personalNumber,
    phoneNumber: data.phoneNumber,
    email: data.email,
    contactPerson: {
      name: data.contactPerson.name,
      title: data.contactPerson.title,
    },
  };
};
