import { AnswerTemplateDtoIn, AnswerTemplateDtoOut } from "../../generated";
import { AnswerTemplate } from "../../model/templates/AnswerTemplate";
import { fromSeverityStatusToGenerated, parseSeverityStatus } from "./parseSeverityStatus";

export const parseAnswerTemplate = (data: AnswerTemplateDtoOut): AnswerTemplate => ({
  id: data.id,
  text: data.text,
  severity: parseSeverityStatus(data.severity),
  alertNote: data.alertNote,
});

export const fromAnswerTemplateToGenerated = (data: AnswerTemplate): AnswerTemplateDtoIn => ({
  text: data.text,
  severity: fromSeverityStatusToGenerated(data.severity),
  alertNote: data.alertNote,
});
