export const formatTimeString = (text: string): string => {
  const parts = text.split(":");
  switch (parts.length) {
    case 2:
      return text;
    case 3:
      return `${parts[0]}:${parts[1]}`;
    default:
      return text;
  }
};
