import { WardRoundTemplate } from "../../../model/templates/WardRoundTemplate";
import { FormWrapper } from "../../ui/form/FormWrapper";
import { TextInput } from "../../ui/form/inputs/TextInput";
import { useForm } from "react-hook-form";
import React, { useEffect } from "react";
import { SearchAndSelectInput } from "../../ui/form/inputs/SearchAndSelectInput";
import { FormSectionWrapper } from "../../ui/form/FormSectionWrapper";
import { useAsyncCall } from "react-hook-async-call";
import { getQuestionnaireTemplates } from "../../../services/questionnaire-template/getQuestionnaireTemplates";
import { useOnMount } from "../../../hooks/useOnMount";
import { SwitchInput } from "../../ui/form/inputs/SwitchInput";
import { Stack } from "@mui/material";
import { ItemDetailHeader } from "../../layout/ItemDetailHeader";
import { AnimatedButton } from "../../ui/buttons/AnimatedButton";
import { Check } from "@mui/icons-material";
import { putWardRoundTemplate } from "../../../services/ward-round-template/putWardRoundTemplate";
import { postWardRoundTemplate } from "../../../services/ward-round-template/postWardRoundTemplate";
import { useAlerts } from "../../../hooks/useAlerts";
import { dummyId } from "../../../model/constants/dummyId";
import { Item } from "../../ui/menu/Item";
import { IconMenu } from "../../ui/menu/IconMenu";

interface Props {
  data: WardRoundTemplate;
  onDeleteClick: () => void;
  onCancelNewClick: () => void;
  onSaveSuccess: (wardRound: WardRoundTemplate) => void;
}

export const WardRoundTemplateDetail = (props: Props) => {
  const isEditing = props.data.id !== dummyId;
  const alerts = useAlerts();
  const form = useForm<WardRoundTemplate>({ defaultValues: props.data });

  const [getQuestionnaires, allQuestionnaires, loadingQuestionnaires] = useAsyncCall(getQuestionnaireTemplates, []);

  const [saveWardRound] = useAsyncCall(
    () => (isEditing ? putWardRoundTemplate(props.data.id, form.getValues()) : postWardRoundTemplate(form.getValues())),
    undefined,
    (wardRound) => {
      props.onSaveSuccess(wardRound);
      alerts.success(isEditing ? "Vizita úspěšně uložena" : "Vizita úspěšně vytvořena");
    },
    () => alerts.error("Pri vytváření vizity nastala chyba")
  );

  useOnMount(() => {
    getQuestionnaires();
  });

  useEffect(() => {
    form.reset(props.data);
  }, [form, props.data]);

  return (
    <>
      <ItemDetailHeader title={props.data.title}>
        <AnimatedButton
          onClick={saveWardRound}
          animation={"scale"}
          endIcon={<Check />}
          variant={"contained"}
          color={"success"}
        >
          Uložit
        </AnimatedButton>
        {(() => {
          if (isEditing) {
            return (
              <IconMenu>
                <Item title={"Smazat vizitu"} onClick={props.onDeleteClick} />
              </IconMenu>
            );
          }

          return (
            <AnimatedButton onClick={props.onCancelNewClick} animation={"scale"} variant={"contained"} color={"error"}>
              Zrušit
            </AnimatedButton>
          );
        })()}
      </ItemDetailHeader>
      <FormWrapper>
        <TextInput
          name={"title"}
          title={"Název"}
          form={form}
          required
          helperText={"Intérní název vizity neviditelný pro pacienta"}
          fieldError={form.formState.errors.title}
        />
        <TextInput
          name={"description"}
          title={"Popisek"}
          form={form}
          required
          multiline
          helperText={"Vysvětlující popisek o cíli konání vizity pro pacienta"}
          fieldError={form.formState.errors.description}
        />
        <FormSectionWrapper title={"Dotazníky"}>
          <SearchAndSelectInput
            name={"questionnaires"}
            form={form}
            options={allQuestionnaires}
            loading={loadingQuestionnaires}
            optionLabel={(option) => option.title}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </FormSectionWrapper>
        <FormSectionWrapper title={"Výzvy"}>
          {form.watch(`challenges`).map((challenge, index) => (
            <SwitchInput
              key={challenge.type}
              title={challenge.type}
              enabled={form.watch(`challenges.${index}.enabled`)}
              onChange={(enabled) => form.setValue(`challenges.${index}.enabled`, enabled)}
              bold
            />
          ))}
        </FormSectionWrapper>
        <FormSectionWrapper title={"Akce"}>
          {form.watch(`actions`).map((action, index) => {
            const enabled = form.watch(`actions.${index}.enabled`);
            return (
              <Stack key={action.type}>
                <SwitchInput
                  title={action.type}
                  enabled={enabled}
                  onChange={(enabled) => form.setValue(`actions.${index}.enabled`, enabled)}
                  bold
                />
                {enabled && (
                  <Stack my={1}>
                    <TextInput
                      name={`actions.${index}.description`}
                      form={form}
                      placeholder={"Vyvětlující popisek pro pacienta."}
                      multiline
                    />
                  </Stack>
                )}
              </Stack>
            );
          })}
        </FormSectionWrapper>
      </FormWrapper>
    </>
  );
};
