import { DateInputProps } from "./props/FormInputProps";
import { TextField } from "@mui/material";
import { InputWrapper } from "./InputWrapper";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Controller, FieldValues } from "react-hook-form";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

export const DateInput = <TFormValues extends FieldValues>({
  disabled = false,
  required = false,
  ...props
}: DateInputProps<TFormValues>) => {
  return (
    <InputWrapper title={props.title} required={required}>
      <Controller
        name={props.name}
        control={props.form.control}
        render={({ field: { onChange, value, name, ref } }) => (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              ampm={false}
              value={value}
              onChange={onChange}
              renderInput={(params) => (
                <TextField
                  inputRef={ref}
                  id={name}
                  required={required}
                  disabled={disabled}
                  size="small"
                  error={!!props.fieldError}
                  helperText={props.fieldError?.message ?? props.helperText}
                  {...props.form?.register(name, {
                    required: required && `Pole ${props.title?.toLowerCase()} nesmí být prázdné`,
                  })}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        )}
      />
    </InputWrapper>
  );
};
