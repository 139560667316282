import { api } from "../api";
import { WardRoundTemplate } from "../../model/templates/WardRoundTemplate";
import {
  fromWardRoundTemplateToGenerated,
  parseWardRoundTemplate,
} from "../../parsers/template/parseWardRoundTemplate";

export const putWardRoundTemplate = async (wardRoundTemplateId: string, wardRoundTemplate: WardRoundTemplate) => {
  const result = await api.services.wardRoundTemplates.updateWardRoundTemplate(
    wardRoundTemplateId,
    fromWardRoundTemplateToGenerated(wardRoundTemplate)
  );
  return parseWardRoundTemplate(result.data);
};
