import { Stack } from "@mui/material";
import { StatsCard } from "./StatsCard";
import { AnimatedButton } from "../../../../ui/buttons/AnimatedButton";
import { ShowChart } from "@mui/icons-material";
import { Link, useParams } from "react-router-dom";
import { NavigationRoutes } from "../../../../../model/constants/NavigationRoutes";
import { useAsyncCall } from "react-hook-async-call";
import { getAllChallenges } from "../../../../../services/patient-challenge/getAllPatientChallenges";
import { useOnMount } from "../../../../../hooks/useOnMount";
import { Loading } from "../../../../ui/loading/Loading";

export const PatientDetailVitality = () => {
  const { patientId } = useParams() as { patientId: string };

  const [getAllChallengesCall, allChallenges, loadingChallenges] = useAsyncCall(() => getAllChallenges(patientId), []);

  useOnMount(() => {
    getAllChallengesCall();
  });

  if (loadingChallenges || !allChallenges) {
    return <Loading />;
  }

  if (allChallenges.filter((challenge) => challenge.enabled).length === 0) {
    return <Stack alignItems={"center"}>Pacient nemá nastavené hlídání vitality</Stack>;
  }

  return (
    <Stack gap={1}>
      <Stack gap={2}>
        <Stack direction={"row"} gap={1} sx={{ flexWrap: "wrap", justifyContent: "space-around" }}>
          {allChallenges.map(
            (challenge) =>
              challenge.enabled && (
                <Stack
                  key={challenge.id}
                  flex={1}
                  flexBasis={"calc(50% - 20px)"}
                  maxWidth={"calc(50% - 20px)"}
                  minWidth={150}
                >
                  <StatsCard
                    type={challenge.type}
                    value={challenge.value}
                    minGoal={challenge.minGoal}
                    maxGoal={challenge.maxGoal}
                  />
                </Stack>
              )
          )}
        </Stack>
        <Stack direction={"row"} gap={1}>
          <AnimatedButton
            to={NavigationRoutes.patientStatistics.getLink(patientId)}
            component={Link}
            animation={"scale"}
            endIcon={<ShowChart />}
            variant={"text"}
            color={"primary"}
          >
            Statistiky
          </AnimatedButton>
        </Stack>
      </Stack>
    </Stack>
  );
};
