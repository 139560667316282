import { Stack, Tab, Tabs, Typography } from "@mui/material";
import { Header } from "../../layout/Header";
import { BarChart } from "../../ui/barchart/BarChart";
import { formatMinutesToTime } from "../../../helpers/formatDateTime";
import { useCallback, useState } from "react";
import { useAsyncCall } from "react-hook-async-call";
import { getSleepStatistics } from "../../../services/statistics/getSleepStatistics";
import { useOnMount } from "../../../hooks/useOnMount";
import { StatisticsPeriod } from "../../../model/enum/StatisticsPeriod";
import { Loading } from "../../ui/loading/Loading";
import { useParams } from "react-router-dom";
import { getPatient } from "../../../services/patient/getPatient";
import { TabPanel } from "../../ui/tabs/TabPanel";
import { BasicChallengeType } from "../../../model/enum/Challenge";
import { getStepsStatistics } from "../../../services/statistics/getStepsStatistics";
import { getWaterStatistics } from "../../../services/statistics/getWaterStatistics";
import { formatLitersToCups } from "../../../helpers/formatLitersToCups";
import { useBreakpointDown } from "../../../hooks/useBreakpointDown";

enum All {
  All = "Vše",
}

type StatisticsTab = BasicChallengeType | All;
const StatisticsTab = { ...BasicChallengeType, ...All };

export const PatientStatistics = () => {
  const { patientId } = useParams() as { patientId: string };
  const [fetchSleepStatistics, sleepData, loadingSleep] = useAsyncCall(getSleepStatistics, []);
  const [fetchStepsStatistics, stepsData, loadingSteps] = useAsyncCall(getStepsStatistics, []);
  const [fetchWaterStatistics, waterData, loadingWater] = useAsyncCall(getWaterStatistics, []);
  const [getPatientCall, patientData] = useAsyncCall(() => getPatient(patientId), undefined);
  const [activeTab, setActiveTab] = useState<StatisticsTab>(StatisticsTab.Sleep);

  const isMobile = useBreakpointDown();

  useOnMount(() => {
    getData();
  });

  const getData = useCallback(async () => {
    getPatientCall();
    fetchSleepStatistics(patientId, StatisticsPeriod.Week);
    fetchWaterStatistics(patientId, StatisticsPeriod.Week);
    fetchStepsStatistics(patientId, StatisticsPeriod.Week);
  }, [fetchStepsStatistics, fetchSleepStatistics, fetchWaterStatistics, patientId, getPatientCall]);

  if (loadingSteps || loadingSleep || loadingWater) {
    return <Loading />;
  }
  return (
    <>
      <Stack>
        <Header
          title={`Statistiky pacienta`}
          subtitle={`${patientData?.firstName} ${patientData?.lastName}`}
          showBackButton
        />
        <Stack>
          <Stack>
            <Tabs
              value={activeTab}
              onChange={(_, activeTab) => setActiveTab(activeTab)}
              orientation={isMobile ? "vertical" : "horizontal"}
            >
              <Tab label={StatisticsTab.Sleep} value={StatisticsTab.Sleep} />
              <Tab label={StatisticsTab.Water} value={StatisticsTab.Water} />
              <Tab label={StatisticsTab.Steps} value={StatisticsTab.Steps} />
              <Tab label={StatisticsTab.All} value={StatisticsTab.All} />
            </Tabs>
            <TabPanel activeTab={activeTab} index={StatisticsTab.Sleep}>
              <Stack alignItems={"center"}>
                <BarChart data={sleepData} variant={"large"} formatLabel={(value) => formatMinutesToTime(value)} />
              </Stack>
            </TabPanel>
            <TabPanel activeTab={activeTab} index={StatisticsTab.Water}>
              <Stack alignItems={"center"}>
                <BarChart
                  data={waterData.map((data) => {
                    return { ...data, value: formatLitersToCups(data.value) };
                  })}
                  variant={"large"}
                  formatLabel={(value) => `${value} sklenic`}
                />
              </Stack>
            </TabPanel>
            <TabPanel activeTab={activeTab} index={StatisticsTab.Steps}>
              <Stack alignItems={"center"}>
                <BarChart data={stepsData} variant={"large"} formatLabel={(value) => `${value} kroků`} />
              </Stack>
            </TabPanel>
            <TabPanel activeTab={activeTab} index={StatisticsTab.All}>
              <Stack direction={"row"} flexWrap={"wrap"} justifyContent={"space-around"} gap={5}>
                <Stack>
                  <Typography variant={"h3"}>{StatisticsTab.Sleep}</Typography>
                  <BarChart data={sleepData} formatLabel={(value) => formatMinutesToTime(value)} />
                </Stack>
                <Stack>
                  <Typography variant={"h3"}>{StatisticsTab.Water}</Typography>
                  <BarChart
                    data={waterData.map((data) => {
                      return { ...data, value: formatLitersToCups(data.value) };
                    })}
                    formatLabel={(value) => `${value} sklenic`}
                  />
                </Stack>
                <Stack>
                  <Typography variant={"h3"}>{StatisticsTab.Steps}</Typography>
                  <BarChart data={stepsData} formatLabel={(value) => `${value} kroků`} />
                </Stack>
              </Stack>
            </TabPanel>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
