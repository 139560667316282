import { ComponentsOverrides, ComponentsProps, Theme } from "@mui/material";

export const MuiTabsOverrides: {
  defaultProps?: ComponentsProps["MuiTabs"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiTabs"];
} = {
  defaultProps: {
    scrollButtons: false,
  },
  styleOverrides: {
    root: {
      minHeight: 0,
      marginTop: 20,
      marginBottom: 30,
      overflow: "visible !important",
    },
    flexContainer: {
      gap: 15,
      paddingLeft: 15,
      marginLeft: -15,
      paddingRight: 15,
      marginRight: -15,
    },
    scroller: {
      overflow: "visible !important",
    },
    indicator: {
      height: 2,
    },
  },
};
