import { AbstractDialog } from "../../../ui/dialogs/AbstractDialog";
import React, { useEffect, useState } from "react";
import { Button, Stack } from "@mui/material";
import { Check, PersonRemove } from "@mui/icons-material";
import { EndCareDialog } from "./EndCareDialog";
import { Patient } from "../../../../model/patient/Patient";
import { useAsyncCall } from "react-hook-async-call";
import { postEndCare } from "../../../../services/patient-care/postEndCare";
import { useAlerts } from "../../../../hooks/useAlerts";
import { CareTemplateTabs } from "../../care-templates/tabs/CareTemplateTabs";
import { useForm } from "react-hook-form";
import { CareTemplateChallenge } from "../../../../model/templates/CareTemplateChallenge";
import { getCareTemplate } from "../../../../services/care-template/getCareTemplate";
import { Loading } from "../../../ui/loading/Loading";

interface Props {
  open: boolean;
  patient: Patient;
  onClose: (opened: boolean) => void;
  onCareEnd: () => void;
}

export const EditCareDialog = (props: Props) => {
  const alerts = useAlerts();
  const form = useForm<CareTemplateChallenge[]>();
  const [endCareDialogOpen, setEndCareDialogOpen] = useState<boolean>(false);
  const [loadCare, care, loadingCare] = useAsyncCall(getCareTemplate, undefined, undefined, (e) => alerts.error(e));
  const [endCare, , loading] = useAsyncCall(
    postEndCare,
    undefined,
    () => {
      alerts.success(`Péče ${props.patient.care?.name} byla úspěšně ukončena.`);
      setEndCareDialogOpen(false);
      props.onCareEnd();
    },
    (e) => alerts.error(e)
  );

  useEffect(() => {
    if (props.open && props.patient.care) {
      loadCare(props.patient.care.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open, props.patient.care]);

  const buttons = (
    <Stack flex={1} direction={"row"} justifyContent={"space-between"}>
      <Button onClick={() => setEndCareDialogOpen(true)} color={"error"} endIcon={<PersonRemove />}>
        Ukončit péči
      </Button>
      <Button onClick={() => props.onClose(false)} color={"success"} endIcon={<Check />}>
        Uložit
      </Button>

      <EndCareDialog
        patient={props.patient}
        open={endCareDialogOpen}
        onSubmit={() => endCare(props.patient.id)}
        onClose={() => setEndCareDialogOpen(false)}
        loading={loading}
      />
    </Stack>
  );

  return (
    <AbstractDialog
      open={props.open}
      onClose={props.onClose}
      title={`Péče ${care?.title}`}
      width={"lg"}
      buttons={buttons}
    >
      <Stack height={"calc(100vh - 220px)"} maxHeight={"60vh"} overflow={"hidden"}>
        <Stack height={"100%"} pb={3} sx={{ overflowY: "auto", overflowX: "hidden" }}>
          {(() => {
            if (loadingCare) {
              return <Loading />;
            } else if (care) {
              // TODO: https://github.com/mild-blue/health-warden/issues/645
              return (
                <CareTemplateTabs
                  care={care}
                  form={form}
                  enableEdit={false}
                  onSaveWardRound={() => {
                    return;
                  }}
                  onDeleteWardRound={() => {
                    return;
                  }}
                />
              );
            }
          })()}
        </Stack>
      </Stack>
    </AbstractDialog>
  );
};
