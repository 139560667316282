import { PatientCareDtoOut } from "../../generated";
import { PatientCare } from "../../model/patient/PatientCare";

export const parsePatientCare = (data: PatientCareDtoOut): PatientCare => {
  return {
    id: data.id,
    name: data.name,
    description: data.description,
    treatmentBegin: new Date(data.treatmentBegin),
    treatmentEnd: data.treatmentEnd ? new Date(data.treatmentEnd) : undefined,
    createdAt: new Date(data.createdAt),
    nextWardRoundDate: data.upcomingWardRoundOccurrence
      ? new Date(data.upcomingWardRoundOccurrence.occurredAt)
      : undefined,
  };
};
