interface Props {
  size?: "sm" | "md";
}

export const Arrow = ({ size = "md" }: Props) => {
  switch (size) {
    case "sm":
      return (
        <svg width="40" height="19" viewBox="0 0 40 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M40 9.5L25 0.839746V18.1603L40 9.5ZM0 11H26.5V8H0V11Z" fill="#E5E5E5" />
        </svg>
      );
    case "md":
      return (
        <svg width="80" height="19" viewBox="0 0 80 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M80 9.5L65 0.839746V18.1603L80 9.5ZM0 11H66.5V8H0V11Z" fill="#E5E5E5" />
        </svg>
      );
  }
};
