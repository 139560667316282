import star from "../../../assets/star.png";
import { Colors } from "../../../theme/colors";
import { Stack, SxProps } from "@mui/material";

interface Props {
  variant?: "large" | "normal";
  sx?: SxProps;
}

const sizes = {
  normal: 18,
  large: 24,
};
export const StarIcon = ({ variant = "normal", ...props }: Props) => {
  const size = variant === "normal" ? sizes.normal : sizes.large;

  return (
    <Stack
      sx={{
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        width: `${size + 7}px`,
        height: `${size + 7}px`,
        boxShadow: 1,
        bgColor: Colors.white,
        ...props.sx,
      }}
    >
      <img src={star} alt="star" width={`${size}px`} height={`${size}px`} />
    </Stack>
  );
};
