import { TextInput } from "../../../ui/form/inputs/TextInput";
import { AbstractDialog } from "../../../ui/dialogs/AbstractDialog";
import { AnimatedButton } from "../../../ui/buttons/AnimatedButton";
import AddIcon from "@mui/icons-material/Add";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { SaveOutlined } from "@mui/icons-material";
import { useAlerts } from "../../../../hooks/useAlerts";
import { useAsyncCall } from "react-hook-async-call";
import { QuestionnaireTemplate } from "../../../../model/templates/QuestionnaireTemplate";
import { postQuestionnaireTemplate } from "../../../../services/questionnaire-template/postQuestionnaireTemplate";
import { putQuestionnaireTemplate } from "../../../../services/questionnaire-template/putQuestionnaireTemplate";

interface Props {
  open: boolean;
  data?: QuestionnaireTemplate;
  onClose: (opened: boolean) => void;
  onSubmit?: (updatedQuestionnaire: QuestionnaireTemplate) => void;
}

export const QuestionnaireDetailDialog = (props: Props) => {
  const [isEditing, setIsEditing] = useState(false);

  const form = useForm<QuestionnaireTemplate>({
    mode: "onChange",
    defaultValues: {
      title: "",
      questions: [],
    },
  });
  const [saveQuestionnaireCall] = useAsyncCall(
    () =>
      props.data?.id
        ? putQuestionnaireTemplate(props.data.id, form.getValues())
        : postQuestionnaireTemplate(form.getValues()),
    undefined,
    (result) => {
      if (props.onSubmit) {
        props.onSubmit(result);
      }
      alerts.success(isEditing ? "Dotazník uložen" : "Dotazník vytvořen");
      handleClose();
    }
  );
  const alerts = useAlerts();

  useEffect(() => {
    if (props.data) {
      setIsEditing(true);
      form.setValue("title", props.data.title);
      form.setValue("questions", props.data.questions);
    }
  }, [form, props.data]);

  const handleFormSubmit = async () => {
    await saveQuestionnaireCall();
  };

  const handleClose = () => {
    props.onClose(false);

    // because of fadeout animation
    setTimeout(() => {
      form.reset();
      setIsEditing(false);
    }, 200);
  };

  const actionButtons = (
    <AnimatedButton
      animation={"scale"}
      endIcon={isEditing ? <SaveOutlined /> : <AddIcon />}
      disabled={!form.formState.isDirty || !form.formState.isValid}
      onClick={handleFormSubmit}
    >
      {isEditing ? "Uložit" : "Přidat"}
    </AnimatedButton>
  );

  return (
    <AbstractDialog
      open={props.open}
      onClose={handleClose}
      title={isEditing ? "Editovat dotazník" : "Přidat nový dotazník"}
      buttons={actionButtons}
      alertOnClose={form.formState.isDirty}
    >
      <TextInput name={"title"} form={form} title={"Název"} required fieldError={form.formState.errors.title} />
    </AbstractDialog>
  );
};
