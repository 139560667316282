import { WardRoundOccurrence } from "../../../../model/ward-round/WardRoundOccurrence";
import { Dialog, DialogTitle, Divider, IconButton, Stack, Typography } from "@mui/material";
import { WardRoundQuestionnaireAnswers } from "../../ward-rounds-detail/ward-rounds/WardRoundQuestionnaireAnswers";
import { WardRoundChallenges } from "../../ward-rounds-detail/ward-rounds/WardRoundChallenges";
import { isPhotoAction } from "../../../../helpers/type-check/isPhotoAction";
import { WardRoundPhotoAction } from "../../ward-rounds-detail/ward-rounds/actions/WardRoundPhotoAction";
import { isMedicineAction } from "../../../../helpers/type-check/isMedicineAction";
import { WardRoundMedicineAction } from "../../ward-rounds-detail/ward-rounds/actions/WardRoundMedicineAction";
import { Loading } from "../../../ui/loading/Loading";
import { useOnMount } from "../../../../hooks/useOnMount";
import { useAsyncCall } from "react-hook-async-call";
import { getOccurrenceDetail } from "../../../../services/patient/getOccurrenceDetail";
import { Colors } from "../../../../theme/colors";
import { Close } from "@mui/icons-material";
import { useAlerts } from "../../../../hooks/useAlerts";
import { WardRoundBlock } from "../../ward-rounds-detail/ward-rounds/WardRoundBlock";

interface Props {
  open: boolean;
  onClose: () => void;
  occurrence: WardRoundOccurrence;
  patientId: string;
}

export const OccurrenceDetailDialog = (props: Props) => {
  const alerts = useAlerts();
  const [getOccurrenceCall, occurrenceData, loading] = useAsyncCall(
    getOccurrenceDetail,
    undefined,
    undefined,
    alerts.error
  );

  useOnMount(() => {
    getOccurrenceCall(props.patientId, props.occurrence.wardRoundId, props.occurrence.id);
  });

  // todo: filter by answeredAt https://github.com/mild-blue/health-warden/issues/1365
  const answeredQuestionnaires = occurrenceData
    ? occurrenceData.questionnaires.filter((questionnaire) => {
        const answeredQuestions = questionnaire.questions.filter((question) => question.userAnswers.length);
        return answeredQuestions.length > 0;
      })
    : [];
  const completedChallenges = occurrenceData ? occurrenceData.challenges.filter((challenge) => !!challenge.value) : [];
  const completedActions = occurrenceData ? occurrenceData.actions.filter((action) => !!action.value) : [];

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth={"lg"}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{ backgroundColor: Colors.grey50 }}
      >
        <DialogTitle>
          <Typography variant={"h3"} sx={{ fontSize: 18 }}>
            {props.occurrence.wardRoundName}
          </Typography>
        </DialogTitle>
        <IconButton sx={{ mr: 1 }} onClick={() => props.onClose()}>
          <Close />
        </IconButton>
      </Stack>
      <Stack>
        {(() => {
          if (loading) {
            return (
              <Stack mb={3}>
                <Loading />
              </Stack>
            );
          }
          if (
            !occurrenceData ||
            (!answeredQuestionnaires.length && !completedActions.length && !completedChallenges.length)
          ) {
            return (
              <Stack alignItems={"center"} mb={3}>
                <Typography fontWeight={"bold"}>Žádná data</Typography>
              </Stack>
            );
          }

          return (
            <Stack gap={0.2} sx={{ "> :last-child": { borderRadius: "0 0 6px 6px" } }}>
              {answeredQuestionnaires.length > 0 && (
                <WardRoundBlock title={"Odpovědi na dotazníky"}>
                  {answeredQuestionnaires.map((questionnaire) => (
                    <WardRoundQuestionnaireAnswers key={questionnaire.id} data={questionnaire} />
                  ))}
                  <Divider />
                </WardRoundBlock>
              )}
              {completedChallenges.length > 0 && <WardRoundChallenges data={completedChallenges} />}
              {completedActions.length > 0 &&
                completedActions.map((action) => {
                  if (isPhotoAction(action.value)) {
                    return <WardRoundPhotoAction key={action.id} data={action.value} />;
                  }
                  if (isMedicineAction(action.value)) {
                    return <WardRoundMedicineAction key={action.id} data={action.value} />;
                  }
                })}
            </Stack>
          );
        })()}
      </Stack>
    </Dialog>
  );
};
