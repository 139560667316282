import { Stack, Switch } from "@mui/material";
import { Colors } from "../../../../../theme/colors";
import { FontWeight } from "../../../../../theme/utils";
import { ReactNode, useState } from "react";
import { Card } from "../../../../ui/cards/Card";
import { useOnMount } from "../../../../../hooks/useOnMount";

interface Props {
  children?: ReactNode;
  title?: string;
  enabled?: boolean;
  onEnabledChange?: (isEnabled: boolean) => void;
}

export const ChallengeSettingsCard = ({ enabled = false, ...props }: Props) => {
  const [active, setActive] = useState<boolean>(false);

  useOnMount(() => {
    setActive(enabled);
  });

  return (
    <Card noPadding>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{ p: "0 15px", backgroundColor: Colors.grey50 }}
      >
        <Stack
          sx={{
            color: active ? Colors.primary : Colors.grey500,
            fontWeight: FontWeight.Bold,
            transition: "all 500ms cubic-bezier(0.4, 0, 0.2, 1)",
          }}
        >
          {props.title}
        </Stack>
        <Stack alignItems={"end"}>
          <Switch
            sx={{ mr: -1 }}
            color={active ? "primary" : "warning"}
            checked={active}
            onChange={(_, checked) => {
              setActive(checked);
              if (props.onEnabledChange) {
                props.onEnabledChange(checked);
              }
            }}
          />
        </Stack>
      </Stack>
      {active && <Stack sx={{ p: "15px" }}>{props.children}</Stack>}
    </Card>
  );
};
