import { api } from "../api";
import { CareTemplate } from "../../model/templates/CareTemplate";
import { fromCareTemplateToGenerated, parseCareTemplate } from "../../parsers/template/parseCareTemplate";

export const putCareTemplate = async (careTemplateId: string, careTemplate: CareTemplate) => {
  const result = await api.services.careTemplates.updateCareTemplate(
    careTemplateId,
    fromCareTemplateToGenerated(careTemplate)
  );
  return parseCareTemplate(result.data);
};
