import { ChallengeSettingsCard } from "../../../../patient-detail/tabs/vitality/ChallengeSettingsCard";
import { InputAdornment, Stack } from "@mui/material";
import { NumberInput } from "../../../../../ui/form/inputs/NumberInput";
import { UseFormReturn } from "react-hook-form";
import { useEffect } from "react";
import { CareTemplateNumericChallenge } from "../../../../../../model/templates/CareTemplateChallenge";

interface Props {
  data: CareTemplateNumericChallenge;
  form: UseFormReturn<CareTemplateNumericChallenge[]>;
  index: number;
}

export const SleepChallenge = (props: Props) => {
  const title = "Hlídání spánku";
  useEffect(() => {
    props.form.setValue(`${props.index}`, props.data);
  }, [props.data, props.form, props.index]);

  props.form.register(`${props.index}.minGoal`, {
    validate: (value) => {
      if (!value || value < 1) {
        return "Počet hodin musí být větší než 0";
      }

      if (value % 1 !== 0) {
        return "Počet hodin musí být celé číslo";
      }

      if (value >= 24) {
        return "Počet hodin musí být menší než 24";
      }

      return true;
    },
  });

  const sleepUnit = () => {
    const value = props.form.watch(`${props.index}.minGoal`);
    if (value === 1) return "hodina";
    if (value < 5) return "hodiny";
    else return "hodin";
  };

  return (
    <ChallengeSettingsCard
      title={title}
      enabled={props.data.enabled}
      onEnabledChange={(change) => props.form.setValue(`${props.index}.enabled`, change)}
    >
      <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
        <strong>Denní cíl</strong>
        <NumberInput
          name={`${props.index}.minGoal`}
          form={props.form}
          title={title}
          hideTitle
          step={1}
          min={1}
          max={24}
          onlyInt
          required
          fieldError={props.form.formState.errors[props.index]?.minGoal}
          inputProps={{ endAdornment: <InputAdornment position="end">{sleepUnit()}</InputAdornment> }}
        />
      </Stack>
    </ChallengeSettingsCard>
  );
};
