import { DialogProps } from "../../../ui/dialogs/props/DialogProps";
import { AbstractDialog } from "../../../ui/dialogs/AbstractDialog";
import { Button, Divider, Stack } from "@mui/material";
import { useAsyncCall } from "react-hook-async-call";
import { postPatient } from "../../../../services/patient/postPatient";
import { useForm } from "react-hook-form";
import { PatientCreate } from "../../../../model/patient/PatientCreate";
import { TextInput } from "../../../ui/form/inputs/TextInput";
import AddIcon from "@mui/icons-material/Add";
import React, { useEffect } from "react";
import { SelectInput } from "../../../ui/form/inputs/SelectInput";
import { Sex } from "../../../../model/enum/Sex";
import { fromEnumToOptions } from "../../../../helpers/fromEnumToOptions";
import { useAlerts } from "../../../../hooks/useAlerts";
import { useNavigate } from "react-router-dom";
import { Patient } from "../../../../model/patient/Patient";
import { putPatient } from "../../../../services/patient/putPatient";

type Props = DialogProps & {
  patient?: Patient;
  onSuccess?: () => void;
};

export const PatientDetailDialog = ({ patient, ...props }: Props) => {
  const alerts = useAlerts();
  const navigate = useNavigate();
  const form = useForm<PatientCreate>({
    defaultValues: {
      tags: [],
      contactPerson: {
        name: "",
        title: "",
      },
    },
  });

  const [addPatient, , loadingAdd] = useAsyncCall(
    postPatient,
    undefined,
    (patient) => {
      navigate(`/patients/${patient.id}`);
      props.onSuccess?.();
    },
    alerts.error
  );

  const [editPatient, , loadingEdit] = useAsyncCall(
    putPatient,
    undefined,
    () => {
      alerts.success("Pacient byl úspěšně uložen");
      props.onClose();
      props.onSuccess?.();
    },
    alerts.error
  );

  useEffect(() => {
    if (patient) {
      form.reset(patient);
    }
  }, [form, patient]);

  return (
    <AbstractDialog
      open={props.open}
      onClose={props.onClose}
      width={"md"}
      title={patient ? "Upravit pacienta" : "Přidat nového pacienta"}
      buttons={
        <Button
          onClick={() => (patient ? editPatient(patient.id, form.getValues()) : addPatient(form.getValues()))}
          endIcon={patient ? undefined : <AddIcon />}
          disabled={loadingAdd || loadingEdit || !form.formState.isValid}
        >
          {patient ? "Uložit" : "Přidat pacienta"}
        </Button>
      }
    >
      <Stack gap={3}>
        <Stack direction={"row"} gap={2}>
          <TextInput title={"Jméno"} name={"firstName"} form={form} required />
          <TextInput title={"Příjmení"} name={"lastName"} form={form} required />
          <SelectInput
            name={"sex"}
            form={form}
            options={fromEnumToOptions(Sex, [Sex.Undefined])}
            title={"Pohlaví"}
            required
          />
        </Stack>
        <Stack gap={1}>
          <Divider />
          {/* todo: addd tags */}
          <Stack direction={"row"} gap={2}>
            <TextInput title={"Rodné číslo"} name={"personalNumber"} form={form} />
            <TextInput title={"Telefonní číslo"} name={"phoneNumber"} form={form} />
            <TextInput title={"E-mail"} name={"email"} form={form} />
          </Stack>
        </Stack>
      </Stack>
    </AbstractDialog>
  );
};
