import { api } from "../api";
import { StatisticsPeriod } from "../../model/enum/StatisticsPeriod";
import { BarChartData } from "../../model/BarChart";
import { fromPeriodToSinceUntil } from "../../helpers/fromPeriodToSinceUntil";
import { parseStatistics } from "../../parsers/parseStatistics";

export const getStepsStatistics = async (patientId: string, period: StatisticsPeriod): Promise<BarChartData[]> => {
  const { since, until } = fromPeriodToSinceUntil(period);
  const response = await api.services.patientChallenge.getPatientChallengesProgress(patientId, since, until);
  return parseStatistics(response.data.steps, since, until);
};
