import { Card as MuiCard, CardActionArea, Stack, SxProps } from "@mui/material";
import { Border } from "../../../theme/utils";
import { ReactNode } from "react";
import { Colors } from "../../../theme/colors";

interface Props {
  children?: ReactNode;
  sx?: SxProps;
  noPadding?: boolean;
  onClick?: () => void;
  variant?: "outlined" | "contained";
}

export const Card = ({ variant = "outlined", ...props }: Props) => {
  const cardContent = <Stack sx={{ p: props.noPadding ? 0 : 1.6, height: "100%" }}>{props.children}</Stack>;

  const containedStyle = variant === "contained" && {
    backgroundColor: Colors.grey200,
  };

  const outlinedStyle = variant === "outlined" && {
    border: Border.Grey.Thin,
  };

  return (
    <MuiCard sx={{ boxShadow: "none!important", ...containedStyle, ...outlinedStyle, ...props.sx }}>
      {(() => {
        if (props.onClick) {
          return (
            <CardActionArea
              onClick={props.onClick}
              sx={{
                height: "100%",
                fontSize: "inherit",
                fontFamily: "inherit",
                fontWeight: "inherit",
                lineHeight: "inherit",
              }}
            >
              {cardContent}
            </CardActionArea>
          );
        }

        return cardContent;
      })()}
    </MuiCard>
  );
};
