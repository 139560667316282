import { CriticalPatientType, SeverityDetailDtoOut } from "../generated";
import { SeverityDetail } from "../model/SeverityDetail";
import { SeverityReason } from "../model/enum/SeverityReason";

import { parseAnsweredQuestion } from "./question/parseAnsweredQuestion";

export const parseSeverityDetail = (data: SeverityDetailDtoOut): SeverityDetail => {
  return {
    messages: data.messages,
    reasons: data.types.map(parseSeverityType),
    criticalAnswers: Object.keys(data.criticalAnswers).map((questionnaireId) => {
      return {
        questionnaireId,
        answeredQuestions: data.criticalAnswers[questionnaireId].map(parseAnsweredQuestion),
      };
    }),
  };
};

const parseSeverityType = (data: CriticalPatientType): SeverityReason => {
  switch (data) {
    case CriticalPatientType.Answer:
      return SeverityReason.Answer;
    case CriticalPatientType.HeartRate:
      return SeverityReason.HeartRate;
    case CriticalPatientType.Pressure:
      return SeverityReason.Pressure;
  }
};
