import { FormInputProps } from "./props/FormInputProps";
import { TextField } from "@mui/material";
import { InputWrapper } from "./InputWrapper";

export const TextInput = <TFormValues,>({
  name,
  title,
  disabled = false,
  form,
  required = false,
  placeholder,
  inputProps,
  multiline,
  helperText,
  fieldError,
  hideTitle = false,
}: FormInputProps<TFormValues>) => {
  return (
    <InputWrapper title={hideTitle ? undefined : title} required={required}>
      <TextField
        id={name}
        type={"text"}
        size="small"
        multiline={multiline}
        disabled={disabled}
        required={required}
        placeholder={placeholder}
        helperText={helperText}
        InputProps={inputProps}
        error={!!fieldError}
        {...form.register(name, {
          required: required,
        })}
      />
    </InputWrapper>
  );
};
