export enum WardRoundChallengeType {
  HeartRate = "Měření tepu",
  Pressure = "Měření tlaku",
}

export enum BasicChallengeType {
  Sleep = "Hlídání spánku",
  Steps = "Kroky",
  Water = "Pitný režim",
}

export type ChallengeType = WardRoundChallengeType | BasicChallengeType;

export const ChallengeType = {
  ...WardRoundChallengeType,
  ...BasicChallengeType,
};
