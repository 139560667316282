import { Chip } from "@mui/material";
import { Done } from "@mui/icons-material";

interface Props {
  isCompleted: boolean;
  lastAnsweredAt?: Date;
}

export const CompletionChip = ({ isCompleted, lastAnsweredAt }: Props) => {
  return isCompleted ? (
    <Chip label={"dokončeno"} color={"green"} icon={<Done />} />
  ) : (
    <Chip label={lastAnsweredAt ? "rozpracováno" : "čeká"} color={lastAnsweredAt ? "warning" : "default"} />
  );
};
