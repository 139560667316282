import { Stack, Tab, Tabs } from "@mui/material";
import { TabPanel } from "../../../ui/tabs/TabPanel";
import { CareTemplateTab } from "../../../../model/templates/CareTemplateTab";
import { WardRoundsSchedule } from "./ward-rounds/WardRoundsSchedule";
import { CareTemplateVitality } from "./vitality/CareTemplateVitality";
import { useState } from "react";
import { useBreakpointDown } from "../../../../hooks/useBreakpointDown";
import { UseFormReturn } from "react-hook-form";
import { CareTemplateChallenge } from "../../../../model/templates/CareTemplateChallenge";
import { CareTemplate } from "../../../../model/templates/CareTemplate";
import { WardRoundEntry } from "../../../../model/ward-round/WardRoundEntry";

interface Props {
  care: CareTemplate;
  form: UseFormReturn<CareTemplateChallenge[]>;
  enableEdit?: boolean;
  onSaveWardRound: (data: WardRoundEntry, index?: number) => void;
  onDeleteWardRound: (index: number) => void;
}

export const CareTemplateTabs = ({ enableEdit = true, ...props }: Props) => {
  const isMobile = useBreakpointDown();
  const [activeTab, setActiveTab] = useState<CareTemplateTab>(CareTemplateTab.WardRoundsSchedule);

  return (
    <Stack>
      <Tabs
        value={activeTab}
        onChange={(_, activeTab) => setActiveTab(activeTab)}
        orientation={isMobile ? "vertical" : "horizontal"}
        sx={{ my: 0, mb: 3 }}
      >
        <Tab label={"Rozvrh vizit"} />
        <Tab label={"Sledování vitality"} />
      </Tabs>
      <TabPanel
        activeTab={activeTab}
        index={CareTemplateTab.WardRoundsSchedule}
        sx={{ pointerEvents: enableEdit ? "all" : "none" }}
      >
        <WardRoundsSchedule
          wardRounds={props.care.wardRounds}
          onSaveWardRound={props.onSaveWardRound}
          onDeleteWardRound={props.onDeleteWardRound}
          selectedCare={props.care}
        />
      </TabPanel>
      <TabPanel
        activeTab={activeTab}
        index={CareTemplateTab.Vitality}
        sx={{ pointerEvents: enableEdit ? "all" : "none" }}
      >
        <CareTemplateVitality challenges={props.care.challenges} form={props.form} />
      </TabPanel>
    </Stack>
  );
};
