import { ComponentsOverrides, ComponentsProps, Theme } from "@mui/material";
import { Colors } from "../colors";

export const MuiTextField: {
  defaultProps?: ComponentsProps["MuiTextField"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiTextField"];
} = {
  styleOverrides: {
    root: {
      background: "none !important",
      ".MuiInputBase-root": {
        background: Colors.white,
      },
    },
  },
};
