import { Colors } from "../../../theme/colors";

interface Props {
  size?: number;
}

export const BendedLine = ({ size = 12 }: Props) => {
  return (
    <svg width={size} height={size} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1C1 1 1 10 11.5 10" stroke={Colors.grey200} strokeWidth="2" />
    </svg>
  );
};
