import { MinProgressSummaryDtoOut } from "../generated";
import { BarChartData } from "../model/BarChart";
import moment from "moment";

export const parseStatistics = (data: MinProgressSummaryDtoOut, since: string, until: string): BarChartData[] => {
  let barChartData = data.progress.map((dailyProgress): BarChartData => {
    return {
      date: new Date(dailyProgress.timestamp),
      value: dailyProgress.value,
      goalMet: dailyProgress.value >= data.minGoal,
    };
  });
  barChartData = sortByDate(barChartData);
  return fillSpacesBetweenDates(barChartData, since, until);
};

export const fillSpacesBetweenDates = (items: BarChartData[], since: string, until: string): BarChartData[] => {
  const checkedMoment = moment(since);
  const difference = moment(until).diff(moment(since), "days");

  for (let i = 0; i < difference; i++) {
    checkedMoment.add(1, "days");
    const sameDayItemExists = items.some((item) => moment(item.date).isSame(checkedMoment, "days"));
    if (!sameDayItemExists) {
      items.push({
        goalMet: false,
        value: 0,
        date: checkedMoment.toDate(),
      });
    }
  }
  return sortByDate(items);
};

const sortByDate = (items: BarChartData[]): BarChartData[] => {
  return items.sort((a, b) => {
    const aMoment = moment(a.date);
    const bMoment = moment(b.date);
    if (aMoment.isBefore(bMoment)) return -1;
    if (aMoment.isAfter(bMoment)) return 1;
    return 0;
  });
};
