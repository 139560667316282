import { Button, Stack } from "@mui/material";
import { useRef, useState } from "react";
import { ScheduleItem } from "./ScheduleItem";
import { WardRoundEntry } from "../../../../../model/ward-round/WardRoundEntry";
import AddIcon from "@mui/icons-material/Add";
import { Arrow } from "../../../../../assets/Arrow";
import { AddWardRoundToCareDialog } from "./dialogs/AddWardRoundToCareDialog";
import { CareTemplate } from "../../../../../model/templates/CareTemplate";
import { CareType } from "../../../../../model/enum/CareType";
import { StackProps } from "@mui/material/Stack/Stack";
import { ScheduleStartEndItem } from "./ScheduleStartEndItem";

interface Props {
  selectedCare: CareTemplate;
  wardRounds: WardRoundEntry[];
  onSaveWardRound: (data: WardRoundEntry, index?: number) => void;
  onDeleteWardRound: (index: number) => void;
}

export const WardRoundsSchedule = (props: Props) => {
  const [wardRoundDetailDialogOpen, setWardRoundDetailDialogOpen] = useState(false);
  const [selectedWardRound, setSelectedWardRound] = useState<WardRoundEntry>();
  const [selectedWardRoundIndex, setSelectedWardRoundIndex] = useState<number>();
  const container = useRef<HTMLInputElement>(null);

  const showInReverseOrder = props.selectedCare.type === CareType.Preoperative;

  const defaultStackProps: StackProps = {
    direction: "row",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    alignItems: "center",
    gap: 2,
  };

  const reverseStackProps: StackProps = {
    ...defaultStackProps,
    direction: "row-reverse",
    justifyContent: "flex-end",
    flexWrap: "wrap-reverse",
  };

  return (
    <>
      <Stack {...(showInReverseOrder ? reverseStackProps : defaultStackProps)} ref={container}>
        <Stack {...defaultStackProps}>
          {showInReverseOrder && <Arrow size={"sm"} />}
          <ScheduleStartEndItem>Propuštění pacienta</ScheduleStartEndItem>
        </Stack>
        <Stack {...defaultStackProps}>
          <Stack {...defaultStackProps}>
            {props.wardRounds.map((wardRound, index) => (
              <ScheduleItem
                key={index}
                wardRound={wardRound}
                containerRef={container}
                onEdit={() => {
                  setSelectedWardRound(wardRound);
                  setWardRoundDetailDialogOpen(true);
                  setSelectedWardRoundIndex(index);
                }}
              />
            ))}
          </Stack>
          <Stack {...defaultStackProps}>
            <Arrow size={"sm"} />
            <Button variant={"contained"} endIcon={<AddIcon />} onClick={() => setWardRoundDetailDialogOpen(true)}>
              Přidat vizitu
            </Button>
          </Stack>
        </Stack>
        {showInReverseOrder && <ScheduleStartEndItem>Nastavení péče pacientovi</ScheduleStartEndItem>}
      </Stack>
      <AddWardRoundToCareDialog
        open={wardRoundDetailDialogOpen}
        onClose={() => {
          setWardRoundDetailDialogOpen(false);
          setSelectedWardRound(undefined);
        }}
        onSaveWardRound={props.onSaveWardRound}
        onDeleteWardRound={props.onDeleteWardRound}
        data={selectedWardRound}
        index={selectedWardRoundIndex}
        selectedCare={props.selectedCare}
      />
    </>
  );
};
