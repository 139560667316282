import { Stack, Typography } from "@mui/material";
import { Colors } from "../../../../theme/colors";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
  title?: string;
}

export const WardRoundBlock = ({ children, title }: Props) => {
  return (
    <Stack sx={{ backgroundColor: Colors.white, p: 1 }}>
      {title && (
        <Typography variant={"h4"} p={0.5} fontSize={18}>
          {title}
        </Typography>
      )}
      {children}
    </Stack>
  );
};
