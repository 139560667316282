import { api } from "../api";
import { parsePatientPaginatedList } from "../../parsers/patient/parsePatientPaginatedList";
import { PatientPaginatedList } from "../../model/patient/PatientPaginatedList";

export const getAllPatients = async (
  currentPage: number,
  pageSize: number,
  personalNumber?: string,
  fullName?: string
): Promise<PatientPaginatedList> => {
  const response = await api.services.patient.getPatients(
    currentPage,
    pageSize,
    personalNumber?.length ? personalNumber : undefined,
    fullName?.length ? fullName : undefined
  );
  return parsePatientPaginatedList(response.data);
};
