import { ItemList } from "../../layout/ItemList";
import { AnimatedButton } from "../../ui/buttons/AnimatedButton";
import AddIcon from "@mui/icons-material/Add";
import React, { useState } from "react";
import { useAsyncCall } from "react-hook-async-call";
import { getAllCareTemplates } from "../../../services/care-template/getAllCareTemplates";
import { CareTemplate } from "../../../model/templates/CareTemplate";
import { CareTemplateDetail } from "./CareTemplateDetail";
import { useOnMount } from "../../../hooks/useOnMount";
import { WardRoundEntry } from "../../../model/ward-round/WardRoundEntry";
import { useAlerts } from "../../../hooks/useAlerts";
import { putCareTemplate } from "../../../services/care-template/putCareTemplate";
import { deleteCareTemplate } from "../../../services/care-template/deleteCareTemplate";
import { CareDetailDialog } from "./tabs/ward-rounds/dialogs/CareDetailDialog";
import { UploadButton } from "../../ui/buttons/UploadButton";
import { importCareTemplate } from "../../../services/care-template/importCareTemplate";

export const CareTemplatesPage = () => {
  const alerts = useAlerts();

  const [selectedCare, setSelectedCare] = useState<CareTemplate>();
  const [cares, setCares] = useState<CareTemplate[]>([]);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [getAllCareTemplatesCall] = useAsyncCall(getAllCareTemplates, [], (result) => {
    if (!result.length) return;
    setCares(result);
    if (!selectedCare) return setSelectedCare(result[0]);
    setSelectedCare(result.find((c) => c.id === selectedCare.id));
  });

  const [saveCareTemplateCall] = useAsyncCall(
    putCareTemplate,
    undefined,
    (result) => {
      updateCare(result);
      alerts.success("Šablona úspěšně uložena");
    },
    () => {
      alerts.error("Šablonu se nepodařilo uložit");
    }
  );

  const [deleteCareTemplateCall] = useAsyncCall(
    deleteCareTemplate,
    undefined,
    () => {
      alerts.success("Šablona úspěšně smazána");
    },
    (e) => {
      alerts.error(e);
    }
  );

  const [importCareTemplateCall] = useAsyncCall(
    importCareTemplate,
    undefined,
    (care) => {
      alerts.success("Šablona úspěšně importována");
      setCares([care, ...cares]);
      setSelectedCare(care);
    },
    () => {
      alerts.error("Šablonu se nepodařilo importovat");
    }
  );

  useOnMount(() => {
    getAllCareTemplatesCall();
  });

  const handleAddWardRoundToCare = (wardRoundEntry: WardRoundEntry, index?: number) => {
    if (!selectedCare) return;

    if (index !== undefined) {
      const wardRounds = [...selectedCare.wardRounds];
      wardRounds[index] = wardRoundEntry;

      updateCare({
        ...selectedCare,
        wardRounds,
      });
      return;
    }

    updateCare({
      ...selectedCare,
      wardRounds: [...selectedCare.wardRounds, wardRoundEntry],
    });
  };

  const handleDeleteWardRoundFromCare = (index: number) => {
    if (!selectedCare) return;
    const wardRounds = [...selectedCare.wardRounds];
    wardRounds.splice(index, 1);
    updateCare({
      ...selectedCare,
      wardRounds,
    });
    alerts.success("Vizita úspěšně odstraněna");
    return;
  };

  const updateCare = (updatedCare: CareTemplate) => {
    setCares(cares.map((c) => (c.id === selectedCare?.id ? updatedCare : c)));
    setSelectedCare(updatedCare);
  };

  const handleDeleteCare = () => {
    if (!selectedCare) return;
    deleteCareTemplateCall(selectedCare.id);
    const filteredCares = cares.filter((c) => c.id !== selectedCare.id);
    setCares(filteredCares);
    setSelectedCare(filteredCares.length ? filteredCares[0] : undefined);
  };

  const handleUpload = (files: FileList) => {
    const file = files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = async (e) => {
      const content = e.target?.result;

      if (typeof content === "string") {
        importCareTemplateCall(content);
      } else {
        alerts.error("Šablonu se nepodařilo importovat");
      }
    };

    reader.readAsText(file);
  };

  const headerButtons = (
    <>
      <AnimatedButton
        onClick={() => setOpenDetailDialog(true)}
        animation={"scale"}
        endIcon={<AddIcon />}
        variant={"contained"}
      >
        Nová šablona
      </AnimatedButton>
      <UploadButton onUpload={handleUpload}>Importovat ze souboru</UploadButton>
    </>
  );

  const careTemplateDetail = selectedCare ? (
    <CareTemplateDetail
      selectedCare={selectedCare}
      onSaveWardRound={handleAddWardRoundToCare}
      onDeleteWardRound={handleDeleteWardRoundFromCare}
      onSave={(challenges) => {
        saveCareTemplateCall(selectedCare?.id, { ...selectedCare, challenges });
      }}
      onDelete={handleDeleteCare}
      onEditCare={updateCare}
    />
  ) : null;

  return (
    <>
      <ItemList
        title={"Šablony péče"}
        items={cares}
        selectedItem={selectedCare}
        headerButtons={headerButtons}
        itemDetail={careTemplateDetail}
        onItemClick={setSelectedCare}
      />
      <CareDetailDialog
        open={openDetailDialog}
        onClose={() => setOpenDetailDialog(false)}
        onSubmit={(care) => {
          setCares((cares) => [care, ...cares]);
          setSelectedCare(care);
        }}
      />
    </>
  );
};
