import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid";
import { AnimatedButton } from "../../ui/buttons/AnimatedButton";
import { Link } from "react-router-dom";
import EastIcon from "@mui/icons-material/East";
import { Patient } from "../../../model/patient/Patient";
import { CriticalIcon } from "../../ui/icons/CriticalIcon";
import moment from "moment";

export const PatientListColumns: GridColumns<Patient> = [
  {
    field: "isCritical",
    headerName: "Stav",
    headerAlign: "center",
    align: "center",
    maxWidth: 70,
    renderCell: (params: GridRenderCellParams) => <CriticalIcon critical={params.value} />,
  },
  {
    field: "firstName",
    headerName: "Jméno",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => <strong>{params.value}</strong>,
  },
  {
    field: "lastName",
    headerName: "Příjmení",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => <strong>{params.value}</strong>,
  },
  {
    field: "personalNumber",
    headerName: "Rodné číslo",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => <strong>{params.value}</strong>,
  },
  {
    field: "lastActivityAt",
    headerName: "Naposledy aktivní",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      if (!params.value) {
        return <strong>-</strong>;
      }
      return <strong> {moment(params.value).fromNow()}</strong>;
    },
  },
  {
    field: "careName",
    headerName: "Péče",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      if (!params.value) {
        return <strong>-</strong>;
      }
      return <strong>{params.value}</strong>;
    },
  },
  {
    field: "action",
    headerName: "",
    sortable: false,
    align: "center",
    minWidth: 150,
    renderCell: (params: GridRenderCellParams) => (
      <AnimatedButton
        to={`/patients/${params.row.id}`}
        component={Link}
        animation={"scale"}
        endIcon={<EastIcon />}
        variant={"text"}
        color={"primary"}
      >
        Detail
      </AnimatedButton>
    ),
  },
];
