import { SeverityStatus } from "../../../../model/enum/SeverityStatus";
import FlagIcon from "@mui/icons-material/Flag";
import { Colors } from "../../../../theme/colors";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";

interface Props {
  severity: SeverityStatus;
}

export const SeverityIcon = ({ severity }: Props) => {
  switch (severity) {
    case SeverityStatus.High:
      return <FlagIcon sx={{ color: `${Colors.error} !important` }} />;
    case SeverityStatus.No:
      return <FlagOutlinedIcon sx={{ color: `${Colors.grey500} !important` }} />;
  }
};
