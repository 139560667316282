import {
  BloodPressurePatientChallengeDtoOut,
  GoalType,
  NumericPatientChallengeDtoOut,
  PatientChallengeDtoOut,
} from "../../generated";
import { Challenge, ChallengeBase } from "../../model/challenge/Challenge";
import { parseChallengeType } from "./parseChallengeType";

export const parsePatientChallenge = (data: PatientChallengeDtoOut): Challenge => {
  switch (data.goalType) {
    case GoalType.Steps:
      return parseNumericPatientChallenge(data as NumericPatientChallengeDtoOut);
    case GoalType.Water:
      return parseNumericPatientChallenge(data as NumericPatientChallengeDtoOut);
    case GoalType.Sleep:
      return parseNumericPatientChallenge(data as NumericPatientChallengeDtoOut);
    case GoalType.HeartRate:
      return parseNumericPatientChallenge(data as NumericPatientChallengeDtoOut);
    case GoalType.Pressure:
      return parseBloodPressurePatientChallenge(data as BloodPressurePatientChallengeDtoOut);
  }
};

export const parseNumericPatientChallenge = (data: NumericPatientChallengeDtoOut): Challenge => {
  return {
    ...parsePatientChallengeBase(data),
    minGoal: data.minGoal,
    maxGoal: data.maxGoal,
    value: data.goalValue,
  };
};

export const parseBloodPressurePatientChallenge = (data: BloodPressurePatientChallengeDtoOut): Challenge => {
  return {
    ...parsePatientChallengeBase(data),
    minGoal: data.minGoal,
    maxGoal: data.maxGoal,
    value: data.goalValue,
  };
};

const parsePatientChallengeBase = (data: PatientChallengeDtoOut): ChallengeBase => {
  return {
    id: data.id,
    enabled: data.enabled,
    patientId: data.patientId,
    type: parseChallengeType(data.goalType),
  };
};
