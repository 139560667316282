import { CareTemplateDtoIn, CareTemplateDtoOut, CareType as CareTypeGenerated } from "../../generated";
import { CareTemplate } from "../../model/templates/CareTemplate";
import { parseWardRoundEntry } from "../ward-round/parseWardRoundEntry";
import { fromCareChallengeGenerated, parseCareChallenge } from "./parseCareChallenge";
import { CareType } from "../../model/enum/CareType";
import { fromWardRoundEntryToGenerated } from "../ward-round/to-generated/fromWardRoundEntryToGenerated";

export const parseCareTemplate = (data: CareTemplateDtoOut): CareTemplate => ({
  id: data.id,
  title: data.name,
  description: data.description,
  type: parseCareType(data.type),
  challenges: data.challenges.map(parseCareChallenge),
  wardRounds: data.wardRounds.map(parseWardRoundEntry),
});

export const fromCareTemplateToGenerated = (data: CareTemplate): CareTemplateDtoIn => {
  return {
    name: data.title,
    description: data.description,
    type: fromCareTypeToGenerated(data.type),
    challenges: data.challenges.map(fromCareChallengeGenerated),
    wardRounds: data.wardRounds.map(fromWardRoundEntryToGenerated),
  };
};

const parseCareType = (data: CareTypeGenerated): CareType => {
  switch (data) {
    case CareTypeGenerated.Postoperative:
      return CareType.Postoperative;
    case CareTypeGenerated.Preoperative:
      return CareType.Preoperative;
  }
};
const fromCareTypeToGenerated = (data: CareType): CareTypeGenerated => {
  switch (data) {
    case CareType.Postoperative:
      return CareTypeGenerated.Postoperative;
    case CareType.Preoperative:
      return CareTypeGenerated.Preoperative;
  }
};
