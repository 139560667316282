import { ChallengeSettingsCard } from "../../../../patient-detail/tabs/vitality/ChallengeSettingsCard";
import { InputAdornment, Stack } from "@mui/material";
import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { NumberInput } from "../../../../../ui/form/inputs/NumberInput";
import { CareTemplateBloodPressureChallenge } from "../../../../../../model/templates/CareTemplateChallenge";

interface Props {
  form: UseFormReturn<CareTemplateBloodPressureChallenge[]>;
  data: CareTemplateBloodPressureChallenge;
  index: number;
}

export const PressureChallenge = (props: Props) => {
  useEffect(() => {
    props.form.setValue(`${props.index}`, props.data);
  }, [props.data, props.form, props.index]);

  return (
    <ChallengeSettingsCard
      title={"Měření tlaku"}
      enabled={props.data.enabled}
      onEnabledChange={(change) => props.form.setValue(`${props.index}.enabled`, change)}
    >
      <Stack spacing={2}>
        <Stack spacing={1}>
          <strong>Cílové rozmezí od</strong>
          <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
            <strong>Systolický</strong>
            <NumberInput
              name={`${props.index}.minGoal.systolic`}
              form={props.form}
              hideTitle
              step={10}
              min={1}
              max={props.form.watch(`${props.index}.maxGoal.systolic`)}
              onlyInt
              required
              fieldError={props.form.formState.errors[props.index]?.minGoal?.systolic}
              inputProps={{ endAdornment: <InputAdornment position="end">mm Hg</InputAdornment> }}
            />
          </Stack>

          <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
            <strong>Diastolický</strong>
            <NumberInput
              name={`${props.index}.minGoal.diastolic`}
              form={props.form}
              hideTitle
              step={10}
              min={1}
              max={props.form.watch(`${props.index}.maxGoal.diastolic`)}
              onlyInt
              required
              fieldError={props.form.formState.errors[props.index]?.minGoal?.diastolic}
              inputProps={{ endAdornment: <InputAdornment position="end">mm Hg</InputAdornment> }}
            />
          </Stack>
        </Stack>

        <Stack spacing={1}>
          <strong>Cílové rozmezí do</strong>
          <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
            <strong>Systolický</strong>
            <NumberInput
              name={`${props.index}.maxGoal.systolic`}
              form={props.form}
              hideTitle
              step={10}
              min={props.form.watch(`${props.index}.minGoal.systolic`)}
              onlyInt
              required
              fieldError={props.form.formState.errors[props.index]?.maxGoal?.systolic}
              inputProps={{ endAdornment: <InputAdornment position="end">mm Hg</InputAdornment> }}
            />
          </Stack>

          <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
            <strong>Diastolický</strong>
            <NumberInput
              name={`${props.index}.maxGoal.diastolic`}
              form={props.form}
              hideTitle
              step={10}
              min={props.form.watch(`${props.index}.minGoal.diastolic`)}
              onlyInt
              required
              fieldError={props.form.formState.errors[props.index]?.maxGoal?.diastolic}
              inputProps={{ endAdornment: <InputAdornment position="end">mm Hg</InputAdornment> }}
            />
          </Stack>
        </Stack>
      </Stack>
    </ChallengeSettingsCard>
  );
};
