import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../../model/User";
import { AuthState, initialState } from "./authModel";

export const authSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state: AuthState, action: PayloadAction<User | undefined>) => {
      state.user = action.payload;
    },
  },
});

// Export actions
export const { setUser } = authSlice.actions;

// Export reducer
export default authSlice.reducer;
