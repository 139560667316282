import { IconButton, Stack, Typography } from "@mui/material";
import { ReactNode } from "react";
import { FontFamilyHeadings, FontWeight } from "../../theme/utils";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useBreakpointDown } from "../../hooks/useBreakpointDown";

interface Props {
  title?: string;
  children?: ReactNode;
  subtitle?: ReactNode;
  buttons?: ReactNode;
  showBackButton?: boolean;
}

export const Header = (props: Props) => {
  const isMobile = useBreakpointDown("sm");
  const isTablet = useBreakpointDown("md");
  const navigate = useNavigate();

  return (
    <Stack direction={isMobile ? "column" : "row"} justifyContent={"space-between"} sx={{ mb: 3 }}>
      <Stack direction={isMobile ? "column" : "row"} gap={2} alignItems={isMobile ? "flex-start" : "center"}>
        {props.showBackButton && (
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
        )}
        <Stack>
          <Stack direction={"row"} gap={1}>
            <Typography variant={"h2"}>{props.title}</Typography>
            {props.buttons}
          </Stack>
          {props.subtitle && (
            <Stack
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              sx={{ fontWeight: FontWeight.Bold, fontFamily: FontFamilyHeadings }}
            >
              {props.subtitle}
            </Stack>
          )}
        </Stack>
      </Stack>
      <Stack
        direction={isTablet ? "column" : "row"}
        gap={1}
        mt={isMobile ? 2 : 0}
        alignItems={isMobile ? "flex-start" : "center"}
        justifyContent={"center"}
      >
        {props.children}
      </Stack>
    </Stack>
  );
};
