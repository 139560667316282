import { ActionTemplateDtoIn, ActionTemplateDtoOut, ActionType as ActionTypeGenerated } from "../../generated";
import { ActionType } from "../../model/enum/ActionType";
import { ActionTemplate } from "../../model/templates/ActionTemplate";

export const parseActionTemplate = (data: ActionTemplateDtoOut): ActionTemplate => {
  return {
    id: data.id,
    type: parseActionType(data.type),
    description: data.description,
    enabled: data.isEnabled,
  };
};

const parseActionType = (data: ActionTypeGenerated): ActionType => {
  switch (data) {
    case ActionTypeGenerated.Medicine:
      return ActionType.Medicine;
    case ActionTypeGenerated.Photo:
      return ActionType.Photo;
  }
};
export const fromActionTemplateToGenerated = (data: ActionTemplate): ActionTemplateDtoIn => {
  return {
    type: fromActionType(data.type),
    text: "",
    description: data.description,
    isEnabled: data.enabled,
  };
};

const fromActionType = (data: ActionType): ActionTypeGenerated => {
  switch (data) {
    case ActionType.Medicine:
      return ActionTypeGenerated.Medicine;
    case ActionType.Photo:
      return ActionTypeGenerated.Photo;
  }
};
