import { AbstractDialog } from "../../../ui/dialogs/AbstractDialog";
import { useAsyncCall } from "react-hook-async-call";
import { getAllCareTemplates } from "../../../../services/care-template/getAllCareTemplates";
import { useOnMount } from "../../../../hooks/useOnMount";
import { SelectInput } from "../../../ui/form/inputs/SelectInput";
import { useForm } from "react-hook-form";
import { Alert, Button } from "@mui/material";
import { Patient } from "../../../../model/patient/Patient";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { postAssignCare } from "../../../../services/patient-care/postAssignCare";
import { Loading } from "../../../ui/loading/Loading";
import { useAlerts } from "../../../../hooks/useAlerts";
import { CareAssignment } from "../../../../model/patient/CareAssignment";
import { DateInput } from "../../../ui/form/inputs/DateInput";
import { formatDateTime } from "../../../../helpers/formatDateTime";

interface Props {
  open: boolean;
  onClose: (opened: boolean) => void;
  onSubmit: () => void;
  patient?: Patient;
  patientIds?: string[];
}

export const SelectCareDialog = ({ open, onClose, patient, patientIds, onSubmit }: Props) => {
  const alerts = useAlerts();
  const form = useForm<CareAssignment>({ defaultValues: { startDate: new Date() } });
  const templateId = form.watch("templateId");
  const startDate = form.watch("startDate");

  const [getAllCare, allCare, fetchLoading] = useAsyncCall(getAllCareTemplates, []);
  const [assignCare, , assignLoading] = useAsyncCall(
    postAssignCare,
    undefined,
    () => {
      alerts.success("Pacientovi byla úspěšně nastavená péče");
      onSubmit();
      onClose(false);
    },
    alerts.error
  );

  useOnMount(() => {
    getAllCare();
  });

  const options = allCare.map((care) => ({
    value: care.id,
    label: care.title,
    key: care.id,
  }));

  const handleAssign = () => {
    const ids = patient ? [patient.id] : patientIds ?? [];
    Promise.all(ids.map((id) => assignCare(id, form.getValues())));
  };

  const buttons = (
    <Button
      onClick={() => {
        handleAssign();
      }}
      color={"success"}
      endIcon={<CheckOutlinedIcon />}
      disabled={!templateId || assignLoading}
    >
      Nastavit
    </Button>
  );

  return (
    <AbstractDialog open={open} onClose={onClose} title={"Výběr šablony péče"} width={"md"} buttons={buttons}>
      {(() => {
        if (fetchLoading) {
          return <Loading />;
        }

        return (
          <>
            <SelectInput name={"templateId"} form={form} options={options} title={"Šablona péče"} required />
            <DateInput name={"startDate"} form={form} title={"Datum začátku péče"} type={"date"} required />
            {templateId && (
              <Alert color={"success"}>
                {patient ? (
                  <>
                    Pacientovi{" "}
                    <strong>
                      {patient.firstName} {patient.lastName}
                    </strong>
                  </>
                ) : (
                  <>
                    <strong>{patientIds?.length}</strong> pacientům
                  </>
                )}{" "}
                bude nastavená péče <strong>{options.find((option) => option.value === templateId)?.label}</strong> ode
                dne <strong>{formatDateTime(startDate, "DD.MM.YYYY")}</strong> od{" "}
                <strong>{formatDateTime(startDate, "HH:mm [hodin]")}</strong>.
              </Alert>
            )}
          </>
        );
      })()}
    </AbstractDialog>
  );
};
