import { FormControl, MenuItem, TextField } from "@mui/material";
import { useAlerts } from "../../../../hooks/useAlerts";
import { Controller } from "react-hook-form";
import { SelectInputProps } from "./props/SelectInputProps";
import { Colors } from "../../../../theme/colors";
import { InputWrapper } from "./InputWrapper";

export const SelectInput = <TFormValues, OptionValueType>({
  name,
  title,
  disabled = false,
  form,
  options,
  required = false,
  onChange,
  helperText,
  fieldError,
  optionsValueComparator = (a, b) => a === b,
}: SelectInputProps<TFormValues, OptionValueType>) => {
  const alerts = useAlerts();

  const keyToValue = (key: string): OptionValueType | undefined => {
    const option = options.find((option) => option.key === key);
    if (option !== undefined) {
      return option.value;
    } else {
      alerts.log(`Form select input ${key} has key that was not found in the select options`, key);
      return undefined;
    }
  };

  const valueToKey = (value: OptionValueType): string => {
    const option = options.find((option) => optionsValueComparator(option.value, value));
    if (option !== undefined) {
      return option.key;
    }

    return "";
  };

  const register = form.register(name, { required: required && `Pole ${title?.toLowerCase()} nesmí být prázdné` });

  return (
    <InputWrapper title={title} required={required}>
      <FormControl>
        <Controller
          name={register.name}
          control={form.control}
          render={({ field }) => (
            <TextField
              {...field}
              sx={{ background: Colors.white }}
              select
              size="small"
              onChange={(e) => {
                field.onChange(keyToValue(e.target.value));
                onChange && onChange();
              }}
              /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
              value={valueToKey(field.value as any)} // todo: do not use any
              disabled={disabled}
              required={required}
              error={!!fieldError}
              helperText={fieldError?.message ?? helperText}
            >
              {options.map((option) => (
                <MenuItem key={option.key} value={option.key}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </FormControl>
    </InputWrapper>
  );
};
