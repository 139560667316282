import { styled } from "@mui/material/styles";
import { Button, ButtonProps } from "@mui/material";
import { Link, To } from "react-router-dom";

type AnimatedButtonProps = {
  variant?: "text" | "outlined" | "contained";
  animation: "slide-right" | "scale";
} & ButtonProps<"a", { component?: typeof Link; to?: To }>;

export const AnimatedButton = styled(Button)<AnimatedButtonProps>((props) => ({
  minWidth: 40,
  ".MuiButton-endIcon, .MuiSvgIcon-root": {
    transition: "transform 0.2s ease-in-out",
  },
  "&:hover": {
    ".MuiButton-endIcon, .MuiSvgIcon-root": {
      transform: props.animation === "scale" ? "scale(1.2)" : "translateX(1.5px) !important",
    },
  },
}));
