import { WardRoundChallengeDtoInOut } from "../../generated";
import { ChallengeTemplate } from "../../model/templates/ChallengeTemplate";
import { parseChallengeType } from "../challenges/parseChallengeType";
import { fromChallengeTypeToGenerated } from "../challenges/to-generated/fromChallengeTypeToGenerated";

export const parseChallengeTemplate = (data: WardRoundChallengeDtoInOut): ChallengeTemplate => {
  return {
    enabled: data.enabled,
    type: parseChallengeType(data.type),
  };
};

export const fromChallengeTemplateToGenerated = (data: ChallengeTemplate): WardRoundChallengeDtoInOut => {
  return {
    enabled: data.enabled,
    type: fromChallengeTypeToGenerated(data.type),
  };
};
