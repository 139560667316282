import { Sex } from "../../../model/enum/Sex";
import { Sex as SexGenerated } from "../../../generated";

export const fromSexToGenerated = (data: Sex): SexGenerated => {
  switch (data) {
    case Sex.Female:
      return SexGenerated.Female;
    case Sex.Male:
      return SexGenerated.Male;
    case Sex.Undefined:
      return SexGenerated.Undefined;
  }
};
