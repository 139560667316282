import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AppWrapper } from "./AppWrapper";
import { SnackbarProvider } from "notistack";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { DialogProvider } from "./context/DialogProvider";

const App = () => {
  return (
    <SnackbarProvider anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
      <Provider store={store}>
        <BrowserRouter>
          <DialogProvider>
            <Routes>
              <Route path={"*"} element={<AppWrapper />} />
            </Routes>
          </DialogProvider>
        </BrowserRouter>
      </Provider>
    </SnackbarProvider>
  );
};

export default App;
