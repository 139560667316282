import { ChallengeSettingsCard } from "../../../../patient-detail/tabs/vitality/ChallengeSettingsCard";
import { InputAdornment, Stack } from "@mui/material";
import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { NumberInput } from "../../../../../ui/form/inputs/NumberInput";
import { CareTemplateNumericChallenge } from "../../../../../../model/templates/CareTemplateChallenge";

interface Props {
  data: CareTemplateNumericChallenge;
  form: UseFormReturn<CareTemplateNumericChallenge[]>;
  index: number;
}

export const HeartRateChallenge = (props: Props) => {
  useEffect(() => {
    props.form.setValue(`${props.index}`, props.data);
  }, [props.data, props.form, props.index]);

  return (
    <ChallengeSettingsCard
      title={"Měření tepu"}
      enabled={props.data.enabled}
      onEnabledChange={(change) => props.form.setValue(`${props.index}.enabled`, change)}
    >
      <strong>Cílové rozmezí</strong>
      <Stack spacing={1}>
        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
          <strong>Od</strong>
          <NumberInput
            name={`${props.index}.minGoal`}
            form={props.form}
            hideTitle
            step={10}
            min={1}
            max={props.form.watch(`${props.index}.maxGoal`)}
            onlyInt
            required
            fieldError={props.form.formState.errors[props.index]?.minGoal}
            inputProps={{ endAdornment: <InputAdornment position="end">BPM</InputAdornment> }}
          />
        </Stack>
        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
          <strong>Do</strong>
          <NumberInput
            name={`${props.index}.maxGoal`}
            form={props.form}
            hideTitle
            step={10}
            min={props.form.watch(`${props.index}.minGoal`)}
            onlyInt
            required
            fieldError={props.form.formState.errors[props.index]?.maxGoal}
            inputProps={{ endAdornment: <InputAdornment position="end">BPM</InputAdornment> }}
          />
        </Stack>
      </Stack>
    </ChallengeSettingsCard>
  );
};
