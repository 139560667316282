import { QuestionType } from "../../../../model/enum/QuestionType";
import { Chip, Divider, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material";
import { AnsweredQuestionnaire } from "../../../../model/questions/AnsweredQuestionnaire";
import { QuestionAnswer } from "@mui/icons-material";
import { AnsweredQuestion } from "../../../../model/questions/AnsweredQuestion";

export const WardRoundQuestionnaireAnswers = ({ data }: { data: AnsweredQuestionnaire }) => {
  const answeredQuestions = data.questions.filter((question) => question.userAnswers.length);

  if (answeredQuestions.length === 0 && data.questions.length !== 0) {
    return (
      <Stack alignItems={"center"} my={3}>
        <Typography fontWeight={"bold"}>Žádné otázky zatím nebyly zodpovězeny</Typography>
      </Stack>
    );
  }

  const userAnswers = (answeredQuestion: AnsweredQuestion) => {
    if (answeredQuestion.question.type === QuestionType.MultiChoiceAnswer) {
      return (
        <Stack>
          {answeredQuestion.userAnswers.map((answer, index) => (
            <Stack key={index} direction={"row"}>
              <Typography sx={{ fontWeight: "bold" }} whiteSpace={"pre"}>
                {index + 1}
                {". "}
              </Typography>
              {answer}
            </Stack>
          ))}
        </Stack>
      );
    }
    return <>{answeredQuestion.userAnswers}</>;
  };

  return (
    <List sx={{ p: 0 }}>
      {answeredQuestions.map((answeredQuestion, index) => (
        <Stack key={index}>
          <Divider />
          <ListItem alignItems={"flex-start"}>
            <ListItemIcon>
              <QuestionAnswer />
            </ListItemIcon>
            <ListItemText>
              <Stack direction={"row"} gap={2}>
                <Stack flex={1} gap={1}>
                  <Typography sx={{ fontWeight: "bold" }}>{answeredQuestion.question.text}</Typography>
                  <Stack direction={"row"}>
                    <Typography sx={{ fontWeight: "bold" }} whiteSpace={"pre"}>
                      Odpověd:{" "}
                    </Typography>
                    {userAnswers(answeredQuestion)}
                  </Stack>
                  {answeredQuestion.userNote?.length && (
                    <Stack direction={"row"}>
                      <Typography sx={{ fontWeight: "bold" }} whiteSpace={"pre"}>
                        Komentář:{" "}
                      </Typography>
                      {answeredQuestion.userNote}
                    </Stack>
                  )}
                </Stack>
                <Stack direction={"row"} flex-wrap={"wrap"} gap={1}>
                  <Chip label={data.title} />
                  <Chip label={answeredQuestion.question.type.toLowerCase()} />
                </Stack>
              </Stack>
            </ListItemText>
          </ListItem>
        </Stack>
      ))}
    </List>
  );
};
