import { PagebleDtoOutPatientDtoOut } from "../../generated";
import { PatientPaginatedList } from "../../model/patient/PatientPaginatedList";
import { parsePatient } from "./parsePatient";

export const parsePatientPaginatedList = (data: PagebleDtoOutPatientDtoOut): PatientPaginatedList => {
  return {
    offset: data.offset,
    page: data.pageNumber,
    pageSize: data.pageSize,
    totalElements: data.totalElements,
    totalPages: data.totalPages,
    patients: data.items.map((patient) => parsePatient(patient)),
  };
};
