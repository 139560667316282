import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack } from "@mui/material";
import { Close } from "@mui/icons-material";
import { ReactNode, useState } from "react";
import { Colors } from "../../../theme/colors";
import { Border } from "../../../theme/utils";
import { SaveWarningDialog } from "./SaveWarningDialog";
import { DialogHeader } from "./parts/DialogHeader";

interface Props {
  open: boolean;
  onClose: (opened: boolean) => void;
  title: string;
  width?: "xs" | "md" | "lg";
  smaller?: boolean;
  children?: ReactNode;
  buttons?: ReactNode;
  titleIcon?: ReactNode;
  alertOnClose?: boolean;
}

export const SelectDialog = ({ ...props }: Props) => {
  const [warningDialogOpen, setWarningDialogOpen] = useState<boolean>(false);

  const handleClose = () => {
    props.alertOnClose ? setWarningDialogOpen(true) : props.onClose(false);
  };

  const onWarningClose = (confirmed: boolean) => {
    setWarningDialogOpen(false);
    if (confirmed) props.onClose(false);
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      fullWidth
      sx={{
        ".MuiDialog-paper": { overflow: "unset", borderRadius: 0, maxWidth: "200px" },
        ".MuiMenuItem-root": {
          height: "38px",
          p: "8px 20px",
          "&:not(:last-child)": { borderBottom: Border.Grey.Thin },
        },
      }}
    >
      <DialogHeader sx={{ backgroundColor: Colors.grey50, height: "38px" }}>
        <DialogTitle sx={{ margin: 0 }} variant={"h4"}>
          <Stack direction={"row"} gap={1} alignItems={"center"}>
            {props.titleIcon}
            {props.title}
          </Stack>
        </DialogTitle>
        <IconButton sx={{ mr: -0.8 }} onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogHeader>
      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "center",
          overflow: "unset",
          p: 0,
        }}
      >
        <Stack sx={{ width: "100%" }}>{props.children}</Stack>
      </DialogContent>
      {props.buttons && (
        <DialogActions sx={{ backgroundColor: Colors.grey50, padding: "10px 16px" }}>{props.buttons}</DialogActions>
      )}
      {props.alertOnClose && <SaveWarningDialog open={warningDialogOpen} onClose={onWarningClose} />}
    </Dialog>
  );
};
