import { TagDtoOut } from "../generated";
import { Tag } from "../model/tag/Tag";

export const parseTag = (data: TagDtoOut): Tag => {
  return {
    id: data.id,
    value: data.value,
    category: {
      id: data.id,
      value: data.value,
    },
  };
};
