import { DialogProps } from "../../../ui/dialogs/props/DialogProps";
import { AbstractDialog } from "../../../ui/dialogs/AbstractDialog";
import { Alert, Box, Button, IconButton, Stack } from "@mui/material";
import { useAsyncCall } from "react-hook-async-call";
import React from "react";
import { useAlerts } from "../../../../hooks/useAlerts";
import { Patient } from "../../../../model/patient/Patient";
import { generatePassword } from "../../../../services/patient/generatePassword";
import { updatePassword } from "../../../../services/patient/updatePassword";
import { Colors } from "../../../../theme/colors";
import { RefreshOutlined, WarningOutlined } from "@mui/icons-material";
import { Loading } from "../../../ui/loading/Loading";
import CheckIcon from "@mui/icons-material/Check";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

type Props = DialogProps & {
  patient: Patient;
  onSuccess?: (password: string) => void;
};

export const LoginInfoDialog = ({ patient, ...props }: Props) => {
  const alerts = useAlerts();

  const [getNewPassword, password, loadingPassword] = useAsyncCall(
    generatePassword,
    undefined,
    undefined,
    alerts.error
  );

  const [setNewPassword, , loadingSetPassword] = useAsyncCall(
    updatePassword,
    undefined,
    (newPassword) => {
      props.onClose();
      props.onSuccess?.(newPassword);
    },
    alerts.error
  );

  return (
    <AbstractDialog
      open={props.open}
      onClose={props.onClose}
      width={"md"}
      title={"Přihlašovací údaje pacienta"}
      buttons={
        <Button
          onClick={() => password && setNewPassword(patient.id, password)}
          endIcon={<CheckIcon />}
          disabled={!password || loadingSetPassword}
        >
          Uložit údaje
        </Button>
      }
    >
      <Stack gap={2}>
        <Stack gap={2} alignItems={"center"}>
          <TextBox label={"Uživatelské jméno"} value={patient.username} />
          {(() => {
            if (password) {
              return (
                <Stack position={"relative"}>
                  <TextBox label={"Heslo"} value={password} />
                  <IconButton
                    onClick={getNewPassword}
                    sx={{ position: "absolute", left: "100%", bottom: 0, marginBottom: 1, marginLeft: 1 }}
                  >
                    <RefreshOutlined />
                  </IconButton>
                </Stack>
              );
            }

            if (loadingPassword) {
              return <Loading />;
            }

            return (
              <Stack gap={1} alignItems={"center"}>
                <Alert color={"info"} icon={<InfoOutlinedIcon />}>
                  Hesla pacientů z bezpečnostních důvodů neznáme. V případě zapomenutého hesla vytvořte nové heslo.
                </Alert>
                <Button onClick={getNewPassword}>Vytvořit nové heslo</Button>
              </Stack>
            );
          })()}
          {password && (
            <Alert color={"warning"} icon={<WarningOutlined />} sx={{ width: "100%" }}>
              Nezapomeňte sdělit heslo pacientovi a uložit nové údaje.
            </Alert>
          )}
        </Stack>
      </Stack>
    </AbstractDialog>
  );
};

export const TextBox = ({ label, value }: { label?: string; value: string }) => {
  return (
    <Stack alignItems={"center"} gap={0.5}>
      {label && <Box>{label}</Box>}
      <Box
        py={1}
        px={2}
        sx={{
          border: `1px solid ${Colors.grey500}`,
          background: Colors.grey50,
          borderRadius: "6px",
          fontFamily: "courier",
          fontSize: 22,
          fontWeight: 700,
          letterSpacing: 1,
          textAlign: "center",
          display: "inline-block",
        }}
      >
        {value}
      </Box>
    </Stack>
  );
};
