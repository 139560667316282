import React, { ChangeEvent, ReactNode, useRef } from "react";
import { AnimatedButton } from "./AnimatedButton";
import { Upload } from "@mui/icons-material";

interface Props {
  children: ReactNode;
  onUpload: (files: FileList) => void;
}

export const UploadButton = (props: Props) => {
  const fileInput = useRef<HTMLInputElement>(null);

  const handleSelectButtonClick = () => {
    if (fileInput && fileInput.current) {
      fileInput.current.click();
    }
  };

  const handleFileSelect = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target as HTMLInputElement;

    if (files) {
      props.onUpload(files);
    }

    // Clear the input
    if (fileInput?.current) {
      fileInput.current.value = "";
    }
  };

  return (
    <>
      <AnimatedButton onClick={handleSelectButtonClick} animation={"scale"} endIcon={<Upload />} variant={"outlined"}>
        {props.children}
      </AnimatedButton>
      <input type="file" accept={"json"} multiple={true} hidden={true} ref={fileInput} onChange={handleFileSelect} />
    </>
  );
};
