import { ChallengeType } from "../../../model/enum/Challenge";
import { GoalType } from "../../../generated";

export const fromChallengeTypeToGenerated = (data: ChallengeType): GoalType => {
  switch (data) {
    case ChallengeType.HeartRate:
      return GoalType.HeartRate;
    case ChallengeType.Pressure:
      return GoalType.Pressure;
    case ChallengeType.Sleep:
      return GoalType.Sleep;
    case ChallengeType.Steps:
      return GoalType.Steps;
    case ChallengeType.Water:
      return GoalType.Water;
  }
};
