import { Stack } from "@mui/material";
import { WellBeingEmoji } from "../../icons/WellBeingEmoji";
import { UseFormReturn } from "react-hook-form";
import { QuestionTemplate } from "../../../../model/templates/QuestionTemplate";
import { TextInput } from "./TextInput";
import { EmojiMood } from "../../../../model/enum/EmojiMood";
import { EmojiType } from "../../../../model/enum/EmojiType";

interface Props {
  mood: EmojiMood;
  type: EmojiType;
  form: UseFormReturn<QuestionTemplate>;
  index: number;
  key: number;
}

export const EmojiInput = (props: Props) => {
  return (
    <Stack spacing={2} alignItems={"center"}>
      <WellBeingEmoji mood={props.mood} type={props.type} />
      <TextInput name={`answers.${props.index}.text`} form={props.form} required />
    </Stack>
  );
};
