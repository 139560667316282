import { QuestionTemplateDetailDialog } from "../dialogs/QuestionTemplateDetailDialog";
import { Item } from "../../../ui/menu/Item";
import { QuestionType } from "../../../../model/enum/QuestionType";
import { Stack } from "@mui/material";
import { Colors } from "../../../../theme/colors";
import { QuestionIcon } from "../icons/QuestionIcon";
import { useState } from "react";
import { QuestionnaireTemplate } from "../../../../model/templates/QuestionnaireTemplate";

interface Props {
  questionType: QuestionType;
  questionnaire: QuestionnaireTemplate;
  onSubmit: (questionnaireTemplate: QuestionnaireTemplate) => void;
}

export const NewQuestionItem = (props: Props) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const titleIcon = (
    <Stack sx={{ color: Colors.grey500 }}>
      <QuestionIcon questionType={props.questionType} />
    </Stack>
  );

  return (
    <>
      <Item title={props.questionType} icon={titleIcon} onClick={() => setDialogOpen(true)} />
      <QuestionTemplateDetailDialog
        questionnaire={props.questionnaire}
        questionType={props.questionType}
        onSubmit={props.onSubmit}
        dialogOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    </>
  );
};
