import { WardRoundOccurrence } from "../../../../../model/ward-round/WardRoundOccurrence";
import { CallMade } from "@mui/icons-material";
import { Colors } from "../../../../../theme/colors";
import { FontWeight, Transition } from "../../../../../theme/utils";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { Card } from "../../../../ui/cards/Card";
import { CompletionChip } from "./CompletionChip";
import { formatDateTime } from "../../../../../helpers/formatDateTime";
import { useBreakpointDown } from "../../../../../hooks/useBreakpointDown";
import moment from "moment";
import React from "react";

interface Props {
  occurrence: WardRoundOccurrence;
  onClick: () => void;
}

export const WardRoundOccurrenceCard = (props: Props) => {
  const isMobile = useBreakpointDown("sm");

  return (
    <Grid item xs={12} md={6} lg={4} xl={3}>
      <Card
        onClick={props.onClick}
        sx={{
          height: "100%",
          backgroundColor: Colors.grey100,
          transition: Transition.Default,
          "&:hover": {
            backgroundColor: Colors.grey200,
            ".openIcon": {
              opacity: 1,
            },
          },
        }}
      >
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
          <Stack>
            <Typography variant={"caption"}>{formatDateTime(props.occurrence.occurredAt)}</Typography>
            <Typography variant={"h3"} sx={{ fontSize: 18 }}>
              {props.occurrence.wardRoundName}
            </Typography>
            <Stack direction={isMobile ? "column" : "row"} spacing={1} alignItems={isMobile ? "flex-start" : "center"}>
              <CompletionChip isCompleted={props.occurrence.isCompleted} lastAnsweredAt={props.occurrence.answeredAt} />
              {props.occurrence.isCompleted && props.occurrence.answeredAt && (
                <Box fontWeight={FontWeight.Bold} sx={{ color: Colors.greenDarker }}>
                  {formatDateTime(props.occurrence.answeredAt)}
                </Box>
              )}
            </Stack>
            <Typography variant={"caption"} mt={1}>
              {(() => {
                if (props.occurrence.isCompleted) {
                  return `Dokončeno ${moment(props.occurrence.answeredAt).from(
                    props.occurrence.occurredAt,
                    true
                  )} po plánovaném začátku`;
                } else if (props.occurrence.answeredAt) {
                  return `Naposledy odpovězeno ${moment(props.occurrence.answeredAt).fromNow()}`;
                } else {
                  return `Plánovaný začátek ${moment(props.occurrence.occurredAt).fromNow()}`;
                }
              })()}
            </Typography>
          </Stack>

          <Stack
            justifyContent={"center"}
            alignItems={"center"}
            className={"openIcon"}
            sx={{ opacity: 0, transition: Transition.Default, color: Colors.primary }}
          >
            <CallMade />
          </Stack>
        </Stack>
      </Card>
    </Grid>
  );
};
