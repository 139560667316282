import { ReactNode, useContext, useEffect, useMemo } from "react";
import { DialogContext } from "../context/DialogContext";
import { v4 as uuidv4 } from "uuid";
import { DialogProps } from "../components/ui/dialogs/props/DialogProps";

export const useDialog = <P extends unknown[]>(render: (dialogProps: DialogProps, ...argArray: P) => ReactNode) => {
  const dialogSetter = useContext(DialogContext);
  const uuid = useMemo(() => uuidv4(), []);

  if (!dialogSetter) {
    throw Error("Dialog is not defined in context!");
  }

  // close dialog when component is unmounted
  useEffect(() => {
    return () => {
      dialogSetter?.setDialogByUUID(uuid, undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  const close = () => {
    dialogSetter.setDialogByUUID(uuid, undefined);
  };

  const show = (...argArray: P) => {
    dialogSetter.setDialogByUUID(
      uuid,
      render(
        {
          open: true,
          onClose: close,
        },
        ...argArray
      )
    );
  };

  return [show, close];
};
