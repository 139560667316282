import { Box, SxProps } from "@mui/material";
import { ReactNode } from "react";

type Props<T = number | string> = {
  index: T;
  activeTab: T;
  children?: ReactNode;
  sx?: SxProps;
};

export const TabPanel = (props: Props) => {
  const { children, activeTab, index } = props;

  return (
    <Box role="tabpanel" hidden={activeTab !== index} sx={props.sx}>
      {activeTab === index && children}
    </Box>
  );
};
