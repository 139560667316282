import { Colors } from "../../../../theme/colors";
import { Button, Stack, Typography } from "@mui/material";
import { NewQuestionCard } from "./NewQuestionCard";
import { QuestionType } from "../../../../model/enum/QuestionType";
import { QuestionnaireTemplate } from "../../../../model/templates/QuestionnaireTemplate";

interface Props {
  questionnaire: QuestionnaireTemplate;
  onSubmit: (questionnaireTemplate: QuestionnaireTemplate) => void;
}

export const NoQuestions = (props: Props) => {
  return (
    <Stack alignItems={"center"} width={"100%"}>
      <Stack width={"100%"} maxWidth={800} bgcolor={Colors.grey50} p={1.5} gap={1}>
        <Typography variant={"h4"} fontSize={18} mb={0}>
          Přidat novou otázku
        </Typography>
        <Stack direction={"row"} flexWrap={"wrap"} gap={1}>
          {Object.values(QuestionType).map((type) => (
            <NewQuestionCard
              key={type}
              questionType={type}
              questionnaire={props.questionnaire}
              onSubmit={props.onSubmit}
            />
          ))}
        </Stack>
        <Button variant={"outlined"} color={"secondary"}>
          Vybrat z existujících otázek...
        </Button>
      </Stack>
    </Stack>
  );
};
