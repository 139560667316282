import AddIcon from "@mui/icons-material/Add";
import { useForm } from "react-hook-form";
import { SaveOutlined } from "@mui/icons-material";
import { useAsyncCall } from "react-hook-async-call";
import { useAlerts } from "../../../../../../hooks/useAlerts";
import { AbstractDialog } from "../../../../../ui/dialogs/AbstractDialog";
import { AnimatedButton } from "../../../../../ui/buttons/AnimatedButton";
import { TextInput } from "../../../../../ui/form/inputs/TextInput";
import { CareTemplate } from "../../../../../../model/templates/CareTemplate";
import { dummyId } from "../../../../../../model/constants/dummyId";
import { postCareTemplate } from "../../../../../../services/care-template/postCareTemplate";
import { useOnMount } from "../../../../../../hooks/useOnMount";
import React, { useEffect } from "react";
import { putCareTemplate } from "../../../../../../services/care-template/putCareTemplate";
import { Button, ButtonGroup } from "@mui/material";
import { CareType } from "../../../../../../model/enum/CareType";
import { InputWrapper } from "../../../../../ui/form/inputs/InputWrapper";
import { Colors } from "../../../../../../theme/colors";

interface Props {
  open: boolean;
  data?: CareTemplate;
  onClose: (opened: boolean) => void;
  onSubmit?: (care: CareTemplate) => void;
}

export const CareDetailDialog = (props: Props) => {
  const isEditing = !!props.data;
  const alerts = useAlerts();

  const defaultValues = {
    id: dummyId,
    type: CareType.Postoperative,
    title: "",
    description: "",
    challenges: [],
    wardRounds: [],
  };

  const form = useForm<CareTemplate>({
    defaultValues,
    mode: "onChange",
  });

  const [saveCareTemplateCall] = useAsyncCall(
    (care) =>
      isEditing && props.data ? putCareTemplate(props.data.id, { ...props.data, ...care }) : postCareTemplate(care),
    undefined,
    (result) => {
      props.onSubmit?.(result);
      alerts.success(isEditing ? "Šablona úspěšně uložena" : "Šablona úspěšně přidána");
      handleClose();
    },
    () => {
      alerts.error("Šablonu se nepodařilo uložit");
    }
  );

  useOnMount(() => {
    form.reset(defaultValues);
  });

  useEffect(() => {
    if (props.data) form.reset(props.data);
  }, [props.data, form]);

  const handleClose = () => {
    props.onClose(false);

    // because of fadeout animation
    setTimeout(() => {
      form.reset();
    }, 200);
  };

  const actionButtons = (
    <AnimatedButton
      animation={"scale"}
      endIcon={isEditing ? <SaveOutlined /> : <AddIcon />}
      onClick={() => saveCareTemplateCall(form.getValues())}
      disabled={!form.formState.isValid || !form.formState.isDirty}
    >
      {isEditing ? "Uložit" : "Přidat"}
    </AnimatedButton>
  );

  return (
    <AbstractDialog
      open={props.open}
      onClose={handleClose}
      title={isEditing ? "Editovat šablonu péče" : "Přidat novou šablonu péče"}
      buttons={actionButtons}
      alertOnClose={form.formState.isDirty}
    >
      <InputWrapper title={"Typ péče"} required={false}>
        <ButtonGroup variant="outlined">
          {Object.values(CareType).map((value, key) => (
            <Button
              key={key}
              value={value}
              onClick={() => {
                form.setValue("type", value, { shouldDirty: true });
              }}
              variant={form.watch("type") === value ? "contained" : "outlined"}
              sx={{ border: `1px solid ${Colors.primary}`, flex: 1 }}
            >
              {value}
            </Button>
          ))}
        </ButtonGroup>
      </InputWrapper>
      <TextInput name={"title"} form={form} title={"Název"} required fieldError={form.formState.errors.title} />
      <TextInput
        multiline
        name={"description"}
        form={form}
        title={"Popis"}
        fieldError={form.formState.errors.description}
      />
    </AbstractDialog>
  );
};
