import { ChallengeProgress, ChallengeProgressValue } from "../../model/challenge/ChallengeProgress";
import {
  BloodPressureChallengeProgressDtoOut,
  GoalType,
  HeartRateChallengeProgressDtoOut,
  WardRoundChallengeDtoOut,
  WardRoundChallengeDtoOutUserAnswer,
} from "../../generated";
import { parseChallengeType } from "./parseChallengeType";

export const parseChallengeProgress = (data: WardRoundChallengeDtoOut): ChallengeProgress => {
  const type = parseChallengeType(data.type);
  return {
    type,
    value: data.userAnswer && parseChallengeProgressValue(data.userAnswer),
  };
};

const parseChallengeProgressValue = (data: WardRoundChallengeDtoOutUserAnswer): ChallengeProgressValue | undefined => {
  switch (data.type) {
    case GoalType.HeartRate:
      return { heartRate: (data as HeartRateChallengeProgressDtoOut).heartRate };
    case GoalType.Pressure:
      return {
        systolic: (data as BloodPressureChallengeProgressDtoOut).bloodPressure.systolic,
        diastolic: (data as BloodPressureChallengeProgressDtoOut).bloodPressure.diastolic,
      };
  }
};
