import { Stack } from "@mui/material";
import { Arrow } from "../../../../../assets/Arrow";
import { Border } from "../../../../../theme/utils";
import { Colors } from "../../../../../theme/colors";
import React from "react";

interface Props {
  days: number;
}

export const NextRoundArrow = (props: Props) => {
  return (
    <Stack alignItems={"center"} sx={{ position: "relative" }}>
      <Arrow />
      <Stack
        alignItems={"center"}
        justifyContent={"center"}
        sx={{
          position: "absolute",
          backgroundColor: "white",
          height: 30,
          width: 30,
          border: Border.Grey.Thick,
          borderRadius: 15,
          transform: "translate(-7.5px, -6px)",
        }}
      >
        <strong>{props.days}</strong>
      </Stack>
      <Stack
        alignItems={"center"}
        justifyContent={"center"}
        sx={{
          position: "absolute",
          transform: "translate(-7.5px, 23px)",
          fontSize: 10,
          color: Colors.grey500,
        }}
      >
        <strong>dny</strong>
      </Stack>
    </Stack>
  );
};
