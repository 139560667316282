import { Autocomplete, Checkbox, CircularProgress, TextField } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { InputWrapper } from "./InputWrapper";
import { SearchAndSelectInputProps } from "./props/FormInputProps";
import { Controller } from "react-hook-form";
import { Colors } from "../../../../theme/colors";

export const SearchAndSelectInput = <TFormValues, OptionValues>({
  disabled = false,
  required = false,
  hideTitle = false,
  optionLabel,
  ...props
}: SearchAndSelectInputProps<TFormValues, OptionValues>) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const register = props.form.register(props.name, {
    required: required && `Pole ${props.title?.toLowerCase()} nesmí být prázdné`,
  });

  return (
    <InputWrapper title={hideTitle ? undefined : props.title} required={required}>
      <Controller
        name={register.name}
        control={props.form.control}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            multiple
            onChange={(_, data) => onChange(data)}
            options={props.options}
            value={value as OptionValues[]}
            isOptionEqualToValue={props.isOptionEqualToValue}
            disableCloseOnSelect
            loading={props.loading}
            getOptionLabel={optionLabel}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                {optionLabel(option)}
              </li>
            )}
            sx={{
              ".MuiChip-root": {
                height: "24px",
              },
              ".MuiInputBase-root": {
                minHeight: "37px",
                paddingTop: "3px !important",
                paddingBottom: "3px !important",
                fieldset: {
                  borderColor: `${Colors.grey200} !important`,
                },
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={props.placeholder}
                size="small"
                disabled={disabled}
                required={required}
                error={!!props.fieldError}
                helperText={props.fieldError?.message ?? props.helperText}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: props.loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    params.InputProps.endAdornment
                  ),
                }}
              />
            )}
          />
        )}
      />
    </InputWrapper>
  );
};
