import { useSnackbar } from "notistack";
import { getApiErrorMessage } from "../helpers/getApiErrorMessage";

export const useAlerts = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const error = (error?: unknown) => {
    const msg = typeof error === "string" ? error : getApiErrorMessage(error);
    enqueueSnackbar(msg, { variant: "error" });
  };

  const success = (msg: string) => {
    enqueueSnackbar(msg, { variant: "success" });
  };

  const info = (msg: string) => {
    enqueueSnackbar(msg, { variant: "info" });
  };

  const warning = (msg: string) => {
    enqueueSnackbar(msg, { variant: "warning" });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const log = (msg?: any, ...optionalParams: any[]) => {
    console.log(msg, ...optionalParams);
  };

  const clear = () => {
    closeSnackbar();
  };

  return {
    error,
    success,
    info,
    warning,
    log,
    clear,
  };
};
