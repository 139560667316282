import badActive from "../../../assets/emoji/bad_active.png";
import badTransparent from "../../../assets/emoji/bad.png";
import goodActive from "../../../assets/emoji/good_active.png";
import goodTransparent from "../../../assets/emoji/good.png";
import excellentActive from "../../../assets/emoji/excellent_active.png";
import excellentTransparent from "../../../assets/emoji/excellent.png";
import { EmojiMood } from "../../../model/enum/EmojiMood";
import { EmojiType } from "../../../model/enum/EmojiType";

interface Props {
  mood: EmojiMood;
  type: EmojiType;
}

export const WellBeingEmoji = (props: Props) => {
  const getImageSource = () => {
    switch (props.mood) {
      case EmojiMood.Bad:
        if (props.type === "transparent") return badTransparent;
        return badActive;
      case EmojiMood.Good:
        if (props.type === "transparent") return goodTransparent;
        return goodActive;
      case EmojiMood.Excellent:
        if (props.type === "transparent") return excellentTransparent;
        return excellentActive;
    }
  };
  return <img src={getImageSource()} width={"120px"} height={"120px"} alt={"Emoji"} />;
};
