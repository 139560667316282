import { Button, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { AbstractDialog } from "../../../../../ui/dialogs/AbstractDialog";
import { CareTemplate } from "../../../../../../model/templates/CareTemplate";
import { WardRoundEntry } from "../../../../../../model/ward-round/WardRoundEntry";

interface Props {
  open: boolean;
  index: number | undefined;
  onClose: () => void;
  onConfirm: () => void;
  selectedCare: CareTemplate;
  selectedWardRound: WardRoundEntry | undefined;
}

export const DeleteWardRoundFromCareDialog = (props: Props) => {
  const actionButtons = (
    <Button
      color={"error"}
      endIcon={<DeleteIcon />}
      onClick={() => {
        props.onConfirm();
        props.onClose();
      }}
    >
      Odstranit
    </Button>
  );

  return (
    <AbstractDialog open={props.open} onClose={props.onClose} title={"Odstranit vizitu z péče"} buttons={actionButtons}>
      <Typography variant={"body1"}>
        Opravdu si přejete odstranit vizitu <strong>{props.selectedWardRound?.wardRoundTemplate.title}</strong> z péče{" "}
        <strong>{props.selectedCare.title}</strong>?
      </Typography>
    </AbstractDialog>
  );
};
