import { AbstractDialog } from "../../../ui/dialogs/AbstractDialog";
import { SwitchInput } from "../../../ui/form/inputs/SwitchInput";
import { useState } from "react";
import { TextInput } from "../../../ui/form/inputs/TextInput";
import { UseFormReturn } from "react-hook-form";
import { QuestionTemplate } from "../../../../model/templates/QuestionTemplate";
import { useOnMount } from "../../../../hooks/useOnMount";
import { Button } from "@mui/material";

interface Props {
  open: boolean;
  onClose: () => void;
  form: UseFormReturn<QuestionTemplate>;
  index: number;
}

export const AlertNoteDialog = (props: Props) => {
  const [enabled, setEnabled] = useState(false);

  useOnMount(() => {
    setEnabled(!!props.form.getValues(`answers.${props.index}.alertNote`)?.length);
  });

  const handleSubmit = () => {
    if (!enabled) {
      props.form.setValue(`answers.${props.index}.alertNote`, "");
    }
    props.onClose();
  };

  const buttons = (
    <Button
      color={"success"}
      onClick={handleSubmit}
      disabled={enabled && !props.form.getValues(`answers.${props.index}.alertNote`)?.length}
    >
      Uložit
    </Button>
  );

  return (
    <AbstractDialog
      size={"xs"}
      open={props.open}
      onClose={props.onClose}
      title={"Upozornění pro pacienta"}
      buttons={buttons}
      alertOnClose={props.form.formState.isDirty}
    >
      <SwitchInput enabled={enabled} onChange={setEnabled} title={"Po zvolení dané odpovědi zobrazit upozornění"} />
      <TextInput
        name={`answers.${props.index}.alertNote`}
        form={props.form}
        disabled={!enabled}
        placeholder={"Text upozornění"}
        multiline
      />
    </AbstractDialog>
  );
};
