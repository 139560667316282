import { SelectDialog } from "../../../ui/dialogs/SelectDialog";
import { SeverityStatus } from "../../../../model/enum/SeverityStatus";
import { SeverityIcon } from "../icons/SeverityIcon";
import { Button } from "@mui/material";
import { useState } from "react";
import { Item } from "../../../ui/menu/Item";
import { Colors } from "../../../../theme/colors";

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (severity: SeverityStatus) => void;
  currentSeverity: SeverityStatus;
}

export const AnswerSeverityDialog = (props: Props) => {
  const [selectedSeverity, setSeverity] = useState<SeverityStatus>(props.currentSeverity);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const handleClick = (severity: SeverityStatus) => {
    if (severity !== selectedSeverity) {
      setIsDirty(true);
      setSeverity(severity);
    }
  };

  const handleSave = () => {
    setIsDirty(false);
    props.onSubmit(selectedSeverity);
  };

  const buttons = (
    <Button color={"success"} onClick={handleSave} disabled={!isDirty} size={"small"}>
      Uložit
    </Button>
  );

  const activeStyle = { fontWeight: "bold", backgroundColor: Colors.grey200 };

  return (
    <SelectDialog
      open={props.open}
      onClose={props.onClose}
      title={"Závažnost odpovědi"}
      buttons={buttons}
      alertOnClose={isDirty}
    >
      {Object.values(SeverityStatus).map((severity) => (
        <Item
          key={severity}
          title={severity}
          icon={<SeverityIcon severity={severity} />}
          onClick={() => handleClick(severity)}
          sx={severity === selectedSeverity ? activeStyle : {}}
        />
      ))}
    </SelectDialog>
  );
};
